import { Helpers } from 'src/app/components/shared/helper-functions';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl, FormArray, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UserService } from '../../userlogin/user.service';
import { Zeugen, Sachschaeden, Bearbeitung } from 'src/app/models/mandat';
import { Injectable } from '@angular/core';
import { FileFormFields } from './file-class';

let fff = FileFormFields;
@Injectable()
export class CommonFunctions {
    constructor(
        public dialog: MatDialog,
        public formBuilder: FormBuilder,
        public helpers: Helpers,
        public datepipe: DatePipe,
        private userService: UserService,
    ) { }
    getMandatForm(statuses, anreden, titels, insurerNames, sachbearbeiter, grundfahrte, begruendungSchuldfragelist,
        fahrzeuggruppen, disableFahrer, werkstatt, werkstattAnsprechpartner, kfzSachverstaendiger,
        kfzAnsprechpartner, gebuehrensatzlist, forderung, z_forderung): FormGroup {
        return this.formBuilder.group({
            id: new FormControl(null, Validators.nullValidator),
            status: new FormControl(
                statuses[0].id,
                Validators.nullValidator
            ),
            gebuehrensatz: new FormControl(),
            bearbeitung: new FormArray([]),
            ugFahrer: new FormControl(false, [Validators.nullValidator]), // New
            ugf_Anrede: new FormControl(anreden[0].id), // [Validators.required, this.customRequiredById.bind(this)]),
            ugf_Titel: new FormControl(titels[0].id, [Validators.nullValidator]),
            ugf_Vorname: new FormControl(null), // [Validators.required]),
            ugf_Nachname: new FormControl(null), // [Validators.required]),
            ugf_Strasse: new FormControl(null), // [Validators.required]),
            ugf_hausNummer: new FormControl(null), // [Validators.required]),
            ugf_Plz: new FormControl(null, [Validators.pattern('[0-9]*')]), // [Validators.required, Validators.pattern('[0-9]*')]),
            ugf_Ort: new FormControl(null), // Validators.required),
            hvName: new FormControl(insurerNames[0].value),
            insurerSelect: new FormControl(insurerNames[0]), // [this.customRequiredByObj.bind(this)]),
            sachbearbeiter: new FormControl(sachbearbeiter[0].value, Validators.nullValidator),
            sachbearbeiterSelect: new FormControl(sachbearbeiter[0], Validators.nullValidator),
            insurerId: new FormControl(null, Validators.nullValidator),
            sachbearbeiterId: new FormControl(0, Validators.nullValidator),
            hvNummer: new FormControl(null, Validators.nullValidator),
            hvSchadenNummer: new FormControl(null, [Validators.nullValidator]),
            unfallDatum: new FormControl(null, Validators.required),
            unfallUhrzeit: new FormControl(null, Validators.nullValidator),
            manualUnfallText: new FormControl(false, Validators.nullValidator),
            unfallhergang: new FormControl(null), // [Validators.required, Validators.minLength(20)]),
            unfallGrundfahrt: new FormControl(
                1, // Privat
                // [this.customRequiredById.bind(this)]
            ),
            unfallBegruendungSchuldfrage: new FormControl(
                begruendungSchuldfragelist[0].id,
                // [this.customRequiredById.bind(this)]
            ),
            unfallStrasse: new FormControl(null), // Validators.required),
            unfallHausnummer: new FormControl(null), // Validators.required),
            unfallPlz: new FormControl(null, Validators.pattern('[0-9]*')), // [Validators.required, Validators.pattern('[0-9]*')]),
            unfallOrt: new FormControl(null), // Validators.required),
            zeugen: new FormArray([]),
            marke: new FormControl(null, [Validators.nullValidator]),
            model: new FormControl(null, [Validators.nullValidator]),
            fahrzeugklasse: new FormControl(fahrzeuggruppen[0].id, [Validators.nullValidator]),
            fahrer: new FormControl(false, [Validators.nullValidator]),
            erstzulassung: new FormControl(null, [Validators.nullValidator]),
            fahrerAnrede: new FormControl(anreden[0].id), // [Validators.required, this.customRequiredById.bind(this)]),
            fahrerTitel: new FormControl(titels[0].id, Validators.nullValidator),
            fahrerVorname: new FormControl({ value: null, disabled: disableFahrer }), // Validators.required),
            fahrerNachname: new FormControl(null), // Validators.required),
            fahrerStrasse: new FormControl(null), // Validators.required),
            fahrerhausNummer: new FormControl(null), // Validators.required),
            fahrerPlz: new FormControl(null, Validators.pattern('[0-9]*')), // [Validators.required, Validators.pattern('[0-9]*')]),
            fahrerOrt: new FormControl(null), // Validators.required),
            schaedenType: new FormControl(null), // Validators.required),
            restwert: new FormControl(null, Validators.nullValidator),
            restwertVerkauf: new FormControl(false, Validators.nullValidator),
            restwertAngebot: new FormControl(null, Validators.nullValidator),
            wiederbeschaffungswert: new FormControl(null, Validators.nullValidator),
            abmeldekosten: new FormControl(null, Validators.nullValidator),
            ausfallart: new FormControl(null, [Validators.nullValidator]),
            mietwagenFirma: new FormControl(null, Validators.nullValidator),
            mietwagenkosten: new FormControl(null, Validators.nullValidator),
            ersatzwagen: new FormControl(false, Validators.nullValidator),
            neuwagenPreis: new FormControl(null, Validators.nullValidator),
            kaufvertragsDatum: new FormControl(null, Validators.nullValidator), // same for ersatzwagen yes/no
            dauerNutzungsausfalls: new FormControl(null, Validators.nullValidator),
            wertminderung: new FormControl(null, Validators.nullValidator),
            artAbrechnung: new FormControl(null, Validators.nullValidator),
            nettoreparaturkosten: new FormControl(null, Validators.nullValidator),
            wpartnerId: new FormControl(null, Validators.nullValidator),
            wAPartnerId: new FormControl(0, Validators.nullValidator),
            kfzpartnerId: new FormControl(null, Validators.nullValidator),
            kfzAPartnerId: new FormControl(0, Validators.nullValidator),
            bruttoreparaturkosten: new FormControl(null, Validators.nullValidator),
            werkstatt: new FormControl(werkstatt[0].value, Validators.nullValidator),
            werkstattSelect: new FormControl(werkstatt[0], Validators.nullValidator),
            werkstattAnsprechpartner: new FormControl(werkstattAnsprechpartner[0].value, Validators.nullValidator),
            wAPSelect: new FormControl(werkstattAnsprechpartner[0], Validators.nullValidator),
            beginReparartur: new FormControl(null, Validators.nullValidator),
            endReparartur: new FormControl(null, Validators.nullValidator),
            honorarkosten: new FormControl(null, Validators.nullValidator),
            kfzSachverstaendiger: new FormControl(kfzSachverstaendiger[0].value, Validators.nullValidator),
            kfzSvSelect: new FormControl(kfzSachverstaendiger[0], Validators.nullValidator),
            kfzAnsprechpartner: new FormControl(kfzAnsprechpartner[0].value, Validators.nullValidator),
            kfzAPSelect: new FormControl(kfzAnsprechpartner[0], Validators.nullValidator),
            abschleppen: new FormControl(false, Validators.nullValidator),
            abschleppDienst: new FormControl(null, Validators.nullValidator),
            abschleppkosten: new FormControl(null, Validators.nullValidator),
            standkosten: new FormControl(null, Validators.nullValidator),
            personenSchaeden: new FormControl(false, Validators.nullValidator),
            personBeschreibung: new FormControl(null, Validators.nullValidator),
            schmerzensgeldforderung: new FormControl(null, Validators.nullValidator),
            heilbehandlungsKosten: new FormControl(null, Validators.nullValidator),
            haushaltsführungsSchaden: new FormControl(null, Validators.nullValidator),
            fahrtKosten: new FormControl(null, Validators.nullValidator),
            sachschaeden: this.formBuilder.array([]),
            anwaltshonorar: new FormControl(null, Validators.nullValidator),
            forderung: new FormControl(forderung, []),
            z_forderung: new FormControl(z_forderung, []),
            z_nettoreparaturkosten: new FormControl(null, Validators.nullValidator),
            z_mietwagenkosten: new FormControl(null, Validators.nullValidator),
            z_abschleppkosten: new FormControl(null, Validators.nullValidator),
            z_standkosten: new FormControl(null, Validators.nullValidator),
            z_abmeldekosten: new FormControl(null, Validators.nullValidator),
            // new fields
            kennzeichen: new FormControl(null, [Validators.required]),
            ugfKennzeichen: new FormControl(null, [Validators.required]),
            leasingfahrzeug: new FormControl(false, [Validators.nullValidator]),
            lf_Type: new FormControl(null, Validators.nullValidator),
            lf_firmenName: new FormControl(null, [Validators.nullValidator]),
            lf_vertragsNummer: new FormControl(null, [Validators.nullValidator]),
            lf_anrede: new FormControl(anreden[0].id, [Validators.nullValidator]),
            lf_titel: new FormControl(titels[0].id, [Validators.nullValidator]),
            lf_vorname: new FormControl(null),
            lf_nachname: new FormControl(null),
            lf_strasse: new FormControl(null, [Validators.nullValidator]),
            lf_hausNummer: new FormControl(null, [Validators.nullValidator]),
            lf_plz: new FormControl(null, [Validators.nullValidator, Validators.pattern('[0-9]*')]),
            lf_ort: new FormControl(null, Validators.nullValidator),
            lf_vertrag: new FormControl(null, Validators.nullValidator),
            lf_erklarung: new FormControl(null, Validators.nullValidator),

            ug_Type: new FormControl(1, Validators.nullValidator),
            ug_firmenName: new FormControl(null, [Validators.nullValidator]),
            ug_ustId: new FormControl(null, [Validators.nullValidator]),
            ug_anrede: new FormControl(anreden[0].id, [Validators.nullValidator]),
            ug_titel: new FormControl(titels[0].id, [Validators.nullValidator]),
            ug_vorname: new FormControl(null),
            ug_nachname: new FormControl(null),
            ug_strasse: new FormControl(null, [Validators.nullValidator]),
            ug_hausNummer: new FormControl(null, [Validators.nullValidator]),
            ug_plz: new FormControl(null, [Validators.nullValidator, Validators.pattern('[0-9]*')]),
            ug_ort: new FormControl(null, Validators.nullValidator),

            benzinSchaden: new FormControl(null, Validators.nullValidator),
            vorhalteKosten: new FormControl(null, Validators.nullValidator),
            fahrbereinigung: new FormControl(null, Validators.nullValidator),
            aufwendungen: new FormControl(null, Validators.nullValidator),
            rueckstufungsKosten: new FormControl(null, Validators.nullValidator),
            polizeibericht: new FormControl(null, Validators.nullValidator),
            svgutachten: new FormControl(null, Validators.nullValidator),
            vollmacht: new FormControl(null, Validators.nullValidator),
            diagnose: new FormControl(null, Validators.nullValidator),
            transportKosten: new FormControl(null, Validators.nullValidator),
            gewinn: new FormControl(null, Validators.nullValidator),
            erwerbsSchaden: new FormControl(null, Validators.nullValidator),

            [fff.mietwagenrechnung]: new FormControl(null, Validators.nullValidator),
            [fff.kaufvertrag]: new FormControl(null, Validators.nullValidator),
            [fff.werkstattrechnung]: new FormControl(null, Validators.nullValidator),
            [fff.honorarkosten]: new FormControl(null, Validators.nullValidator),
            [fff.abmeldekosten]: new FormControl(null, Validators.nullValidator),
            [fff.standkosten]: new FormControl(null, Validators.nullValidator),
            [fff.benzinSchaden]: new FormControl(null, Validators.nullValidator),
            [fff.vorhalteKosten]: new FormControl(null, Validators.nullValidator),
            [fff.fahrbereinigung]: new FormControl(null, Validators.nullValidator),
            [fff.schmerzensgeld]: new FormControl(null, Validators.nullValidator),
            [fff.heilbehandlungsKosten]: new FormControl(null, Validators.nullValidator),
            [fff.transportKosten]: new FormControl(null, Validators.nullValidator),
            [fff.gewinn]: new FormControl(null, Validators.nullValidator),
            [fff.erwerbsSchaden]: new FormControl(null, Validators.nullValidator),
            [fff.fahrtKosten]: new FormControl(null, Validators.nullValidator),
            [fff.haushaltsfuehrungsSchaden]: new FormControl(null, Validators.nullValidator),
            [fff.aufwendungen]: new FormControl(null, Validators.nullValidator),
            [fff.rueckstufungsKosten]: new FormControl(null, Validators.nullValidator),
            [fff.polizeibericht]: new FormControl(null, Validators.nullValidator),
            [fff.vollmacht]: new FormControl(null, Validators.nullValidator),
            [fff.svgutachten]: new FormControl(null, Validators.nullValidator),
        }, {
            validator: Validators.compose([
                this.customBeginEndDate('beginReparartur', 'endReparartur', { 'beginReparartur': true })
            ])
        });
    }
    customRequiredById(control: AbstractControl): { [key: string]: any } | null {
        const id: number = control.value;
        if (id === 0) {
            return { customRequired: true };
        } else {
            return null;
        }
    }
    customRequiredByObj(control: AbstractControl): { [key: string]: any } | null {
        const obj = control.value;
        if (!obj.value) {
            return { customRequired: true };
        } else {
            return null;
        }
    }
    customBeginEndDate(dateField1: string, dateField2: string, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const date1 = new Date(c.get(dateField1).value);
            const date2 = new Date(c.get(dateField2).value);
            // console.log(date1, date2);
            if ((date1 !== null && date2 !== null) && date1 > date2) {
                return validatorField;
            }
            return null;
        };
    }
    openDialogComponenet(component, data) {
        this.dialog.open(component, {
            width: 'auto', disableClose: false,
            data
        });
    }
    createSachschaeden(): FormGroup {
        return this.formBuilder.group({
            id: new FormControl(null, Validators.nullValidator),
            bezeichnung: new FormControl(null, Validators.required),
            kaufpreis: new FormControl(null, Validators.required),
            [fff.beleg]: new FormControl(null, Validators.nullValidator),
            mandatId: new FormControl(null, Validators.nullValidator),
        });
    }
    createBearbeitung(): FormGroup {
        return new FormGroup({
            id: new FormControl(null, Validators.nullValidator),
            timestamp: new FormControl(this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.nullValidator),
            username: new FormControl(this.userService.loggedinUser, Validators.nullValidator),
            comments: new FormControl(null, Validators.required),
            mandatId: new FormControl(null, Validators.nullValidator),
        });
    }
    addBearbeitung(bearbeitung) {
        // this.bearbeitung.controls[('bearbeitung')].reverse();
        bearbeitung.push(this.createBearbeitung());
        const l = bearbeitung.length;
        bearbeitung.controls[l - 1].patchValue({
            timestamp: this.datepipe.transform(Date.now(), 'yyyy-MM-dd'),
            username: this.userService.loggedinUser
        });
        // this.bearbeitung.controls[l - 1].get('username').enable();
        return bearbeitung;
    }
    setexistingZeugen(zeugen: Zeugen[]): FormArray {
        const formArray = new FormArray([]);
        // formArray.removeAt(0);
        zeugen.forEach(s => {
            formArray.push(
                this.formBuilder.group({
                    id: s.id,
                    anrede: s.anrede,
                    titel: s.titel,
                    vorname: s.vorname,
                    nachname: s.nachname,
                    rolle: s.rolle,
                    strasse: s.strasse,
                    hausNummer: s.hausNummer,
                    plz: s.plz,
                    ort: s.ort,
                    mandatId: s.mandatId
                })
            );
        });
        return formArray;
    }
    setexistingSachschaeden(sachschaeden: Sachschaeden[]): FormArray {
        const formArray = new FormArray([]);
        sachschaeden.forEach(s => {
            formArray.push(
                this.formBuilder.group({
                    id: s.id,
                    bezeichnung: s.bezeichnung,
                    kaufpreis: s.kaufpreis,
                    beleg: s.beleg,
                    mandatId: s.mandatId
                })
            );
        });
        return formArray;
    }
    setexistingBearbeitung(bearbeitung: Bearbeitung[]): FormArray {
        const formArray = new FormArray([]);
        bearbeitung.forEach(s => {
            const date = this.datepipe.transform(s.timestamp, 'yyyy-MM-dd');
            formArray.push(
                this.formBuilder.group({
                    id: s.id,
                    timestamp: date,
                    username: s.username,
                    comments: s.comments,
                    mandatId: s.mandatId
                })
            );
        });
        return formArray;
    }
    resetFormControl(mandatForm: FormGroup, formControl): FormGroup {
        mandatForm.get(formControl).reset();
        mandatForm.get(formControl).setValidators(Validators.nullValidator);
        mandatForm.get(formControl).updateValueAndValidity();
        return mandatForm
    }
    setFormControlValidator(mandatForm: FormGroup, formControl): FormGroup {
        mandatForm.get(formControl).setValidators(Validators.required);
        if (formControl === 'lf_plz' ||
            formControl === 'fahrerPlz' ||
            formControl === 'ugf_Plz') {
            mandatForm.get(formControl).setValidators([Validators.required, Validators.pattern('[0-9]*')]);
        }
        if (formControl === 'lf_anrede' ||
            formControl === 'fahrerAnrede' ||
            formControl === 'ugf_Anrede' ||
            formControl === 'fahrzeugklasse') {
            console.log(formControl);
            mandatForm.get(formControl).setValidators([Validators.required, this.customRequiredById.bind(this)]);
        }
        if (formControl === 'lf_titel' ||
            formControl === 'fahrerTitel' ||
            formControl === 'ugf_Titel') {
            mandatForm.get(formControl).setValidators(Validators.nullValidator);
        }
        if (formControl === ('kfzSvSelect')) {
            mandatForm.get(formControl).setValidators([Validators.required, this.customRequiredByObj.bind(this)]);
        }
        mandatForm.get(formControl).updateValueAndValidity();
        return mandatForm
    }
    resetLeasingFZFields(mandatForm: FormGroup, type, anrede, titel, leasingFZFields1: string[], leasingFZFields2: string[]): FormGroup {
        [...new Set([...leasingFZFields1, ...leasingFZFields2])].forEach(formControl => {
            mandatForm = this.resetFormControl(mandatForm, formControl);
        });
        mandatForm.patchValue({
            lf_anrede: anrede,
            lf_titel: titel,
            lf_Type: type
        });
        return mandatForm;
    }
    setLeasingFZValidations(mandatForm: FormGroup, leasingFZFields: string[]): FormGroup {
        leasingFZFields.forEach(formControl => {
            mandatForm = this.setFormControlValidator(mandatForm, formControl);
        });
        return mandatForm;
    }
    handleLeasingFZTypes(mandatForm: FormGroup, type, anrede, titel, leasingFZFields1: string[], leasingFZFields2: string[]): FormGroup {
        if (type === 2) { // 'Unternehmen'
            mandatForm = this.resetLeasingFZFields(mandatForm, type, anrede, titel, leasingFZFields1, leasingFZFields2);
            return this.setLeasingFZValidations(mandatForm, leasingFZFields2);
        } else if (type === 1) {
            mandatForm = this.resetLeasingFZFields(mandatForm, type, anrede, titel, leasingFZFields1, leasingFZFields2);
            console.log(mandatForm);
            return this.setLeasingFZValidations(mandatForm, leasingFZFields1);
        }
    }
    setMandantFahrerValidations(mandatForm: FormGroup, hide: boolean, anrede, titel, mandantFahrerFields: string[]): FormGroup {
        mandantFahrerFields.forEach(formControl => {
            if (hide) {
                mandatForm = this.resetFormControl(mandatForm, formControl);
                (formControl === "fahrerAnrede" || "fahrerTitel") ? mandatForm.patchValue({ fahrerAnrede: anrede, fahrerTitel: titel }) : true;
            } else {
                // Uncomment if mandatory fields are required
                // mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }
        });
        return mandatForm;
    }
    setUGFahrerValidations(mandatForm: FormGroup, hide: boolean, anrede, titel, ugFahrerFields: string[]): FormGroup {
        ugFahrerFields.forEach(formControl => {
            if (hide) {
                mandatForm = this.resetFormControl(mandatForm, formControl);
                (formControl === "ugf_Anrede" || "ugf_Titel") ? mandatForm.patchValue({ ugf_Anrede: anrede, ugf_Titel: titel }) : true;
            } else {
                // Uncomment if mandatory fields are required
                // mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }
        });
        return mandatForm;
    }
    schaedenTypeRadio(mandatForm: FormGroup, eventType, schadenType, totalReqFields, totalAllFields, repReqFields, repAllFields): FormGroup {
        if (schadenType === 'totalschaden') {
            // Uncomment to enable mandatory fields
            /* totalReqFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
            if (eventType === 'change') {
                repAllFields.forEach(formControl => {
                    mandatForm = this.resetFormControl(mandatForm, formControl);
                    // Uncomment to enable mandatory fields
                    // Maybe we can simply remove ausfallart from the list of repAllFields also. To get rid of this if condition
                    /* if (formControl === 'ausfallart') {
                        mandatForm = this.setFormControlValidator(mandatForm, formControl);
                    } */
                });
            }
        } else if (schadenType === 'reparaturschaden') {
            // Uncomment to enable mandatory fields
            /* repReqFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
            if (eventType === 'change') {
                totalAllFields.forEach(formControl => {
                    mandatForm = this.resetFormControl(mandatForm, formControl);
                });
            }
        }
        return mandatForm;
    }
    restwertVerkaufCheck(mandatForm: FormGroup, checked, restwertVerkauftFields): FormGroup {
        if (checked) {
            // Uncomment to enable mandatory fields
            /* restwertVerkauftFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
        } else {
            restwertVerkauftFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
        }
        return mandatForm;
    }
    ausfallartRadio(mandatForm: FormGroup, schaedenType, ausfallart, mietwagenFields,
        totalNutzungausfallFields, repNutzungausfallFields, commonNutzungausfallReqFields, fahrzeuggruppen): FormGroup {
        if (schaedenType === 'totalschaden' && ausfallart === 1) { // 'mietwagen'
            // Uncomment to enable mandatory fields    
            /* mietwagenFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
            totalNutzungausfallFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
            commonNutzungausfallReqFields.forEach(formControl => {
                mandatForm.get(formControl).setValidators(Validators.nullValidator);
                mandatForm.get(formControl).updateValueAndValidity();
            });
        } else if (schaedenType === 'reparaturschaden' && ausfallart === 1) { // 'mietwagen'
            // Uncomment to enable mandatory fields
            /* mietwagenFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
            repNutzungausfallFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
            // Uncomment to enable mandatory fields
            /* commonNutzungausfallReqFields.forEach(formControl => {
                mandatForm.get(formControl).setValidators(Validators.nullValidator);
                mandatForm.get(formControl).updateValueAndValidity();
            }); */
        } else if (schaedenType === 'totalschaden' && ausfallart === 2) { // 'nutzungsausfall'
            // Uncomment to enable mandatory fields
            /* [...totalNutzungausfallFields, ...commonNutzungausfallReqFields].forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
                // if (formControl === 'fahrzeugklasse') {
                //     mandatForm.get(formControl).setValidators([Validators.required, this.customRequiredById.bind(this)]);
                //     mandatForm.get(formControl).updateValueAndValidity();
                // }
            }); */
            mietwagenFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
        } else if (schaedenType === 'reparaturschaden' && ausfallart === 2) { // 'nutzungsausfall'
            // Uncomment to enable mandatory fields
            /* [...repNutzungausfallFields, ...commonNutzungausfallReqFields].forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
                // if (formControl === 'fahrzeugklasse') {
                //     mandatForm.get(formControl).setValidators([Validators.required, this.customRequiredById.bind(this)]);
                //     mandatForm.get(formControl).updateValueAndValidity();
                // }
            }); */
            mietwagenFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
        }
        return mandatForm;
    }
    ersatzwagenToggle(mandatForm, checked, ersatzwagenFields): FormGroup {
        if (checked) {
            // Uncomment to enable mandatory fields
            /* ersatzwagenFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
        } else {
            ersatzwagenFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
        }
        return mandatForm;
    }

    artAbrechnungRadio(mandatForm, value, fictivFields, konkretReqFields, konkretResetFields): FormGroup {
        if (value === 'fiktiv') {
            // Uncomment to enable mandatory fields
            /* fictivFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
            konkretResetFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
        } else if (value === 'konkret') {
            // Uncomment to enable mandatory fields
            /* konkretReqFields.forEach(formControl => {
                mandatForm = this.setFormControlValidator(mandatForm, formControl);
            }); */
            fictivFields.forEach(formControl => {
                mandatForm = this.resetFormControl(mandatForm, formControl);
            });
        }
        return mandatForm;
    }
}
