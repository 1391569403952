import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { NativeElementInjectorDirective } from './../../shared/native-element.directive';
import { FileDetails, FileFormFields } from './file-class';
import { CommonFunctions } from './common-functions';
import { CreateinsurerComponent } from './../../insurers/createinsurer/createinsurer.component';
import { Sachbearbeiter } from './../../../models/insurer';
import { CreatekfzpartnerComponent } from './../../kfzpartners/createkfzpartner/createkfzpartner.component';
import { CreatemandantComponent } from './../../mandanten/createmandant/createmandant.component';
import { KFZPartner, KFZAnsprechPartner } from 'src/app/models/sachverstaendige';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, HostListener, ViewChild, AfterViewInit, AfterContentInit, Input, EventEmitter, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MandateService } from 'src/app/components/mandate/mandate.service';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormArray,
  FormBuilder
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Mandant } from 'src/app/models/mandant';
import { MandantenService } from 'src/app/components/mandanten/mandanten.service';
import { Mandat, Sachschaeden, Zeugen, Bearbeitung } from 'src/app/models/mandat';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Location, DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { MatSelectChange } from '@angular/material/select';
import { Insurer } from 'src/app/models/insurer';
import { InsurerService } from 'src/app/components/insurers/insurer.service';
import { WerkstattPartner, AnsprechPartner } from 'src/app/models/werkstatt';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { WerkstattPartnerService } from 'src/app/components/werkstattpartners/werkstattpartner.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { KfzpartnerService } from 'src/app/components/kfzpartners/kfzpartner.service';
import { CreateWerkstattPartnerComponent } from '../../werkstattpartners/create-werkstatt-partner/create-werkstatt-partner.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Helpers } from '../../shared/helper-functions';
import { SubSink } from 'subsink';
import { browserRefresh } from 'src/app/app.component';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CUSTOM_DATE_FORMAT } from '../../shared/date-format';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';

@Component({
  selector: 'app-createmandat',
  templateUrl: './createmandat.component.html',
  styleUrls: ['./createmandat.component.css', '../../../../assets/global.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }, CommonFunctions, Helpers,
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatemandatComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  //#region Initializations
  zeugenFormData: any;
  zeugenDataSource: MatTableDataSource<AbstractControl>;
  schadenFormData: any;
  schadenDataSource: MatTableDataSource<AbstractControl>;
  mandatForm: FormGroup;
  mandant: Mandant;
  mandat: Mandat;
  wPartners: WerkstattPartner[] = [];
  werkstattIsSet = false;
  kfzPartners: KFZPartner[] = [];
  svIsSet = false;
  insurers: Insurer[] = [];
  mandatID: string;
  mandantID: string;
  schaedenType: string;
  // ausfallart: string; // Total
  artAbrechnung: string;
  // ausfallart1: string; // Reparatur
  ersatzwagen: boolean;
  personenSchaeden = false;
  abschleppen: string;
  disableBearbeitung = false;
  disableFahrer = false;
  public errMsg = {
    message: 'No alerts'
  };
  saved = false;
  created = false;
  zeugenAddFlag = false;
  zeugenEditFlag = false;
  schadenAddFlag = false;
  schadenEditFlag = false;
  aBelegAddFlag = false;
  aBelegEditFlag = false;
  statuses = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  anreden = [
    { id: 0, value: '-- Anrede auswählen --' },
  ];
  titels = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  objectTypes = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  zeugen_rolle = [
    { id: 0, value: '-- Rolle auswählen --' },
  ];
  grundfahrte = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  gebuehrensatzlist = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  ausfallarts = [{ id: 0, value: '-- Bitte auswählen --' }];
  begruendungSchuldfragelist = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  fahrzeuggruppen = [
    { id: 0, klasse: '-- Bitte auswählen --' },
  ];
  insurerNames = [
    { value: '', viewValue: '-- Bitte auswählen --', id: '' },
  ];
  filteredOptions: Observable<string[]>;
  werkstatt = [
    { value: '', viewValue: '-- Bitte auswählen --', id: '' }
  ];
  werkstattAnsprechpartner = [
    { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
  ];
  sachbearbeiter = [
    { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
  ];
  kfzSachverstaendiger = [
    { value: '', viewValue: '-- Bitte auswählen --', id: '' }
  ];
  kfzAnsprechpartner = [
    { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
  ];

  // totalSchaeden = true;
  @ViewChild(MatSort) sort: MatSort;
  zeugen: FormArray;
  sachschaeden: FormArray;
  bearbeitung: FormArray;
  zeugen1: Zeugen[] = [];
  sachschaeden1: Sachschaeden[] = [];
  bearbeitung1: Bearbeitung[] = [];
  displayZeugenColumns = ['rolle', 'name', 'strasse', 'hausNummer', 'plz', 'ort', 'options'];
  displaySchadenColumns = ['bezeichnung', 'kaufpreis', 'options'];
  displayABelegColumns = ['timestamp', 'username', 'comments', 'options'];
  error: string;
  userId = '1';
  uploadResponse = { status: '', message: '', filePath: '' };
  aBelegFormData: any;
  aBelegDataSource: MatTableDataSource<AbstractControl>;
  aBelegIndex: any;
  zeugenIndex: any;
  schadenIndex: any;
  wPartnerID: any;
  kfzPartnerID: any;
  // helpers = new Helpers();
  pageMode = 'create';
  private subs = new SubSink();
  claimed: boolean;
  forderung: number;
  z_forderung: number;
  percentage: any;
  pdfSrc: any;
  // Define fields of individual groups in the mandatform for the validations
  leasingFZFields1 = ['lf_anrede', 'lf_titel', 'lf_vorname', 'lf_nachname', 'lf_strasse', 'lf_hausNummer', 'lf_plz', 'lf_ort'];
  leasingFZFields2 = ['lf_firmenName', 'lf_vertragsNummer', 'lf_strasse', 'lf_hausNummer', 'lf_plz', 'lf_ort'];
  mandantFahrerFields = ['fahrerAnrede', 'fahrerTitel', 'fahrerVorname', 'fahrerNachname', 'fahrerStrasse', 'fahrerhausNummer', 'fahrerPlz', 'fahrerOrt'];
  ugFahrerFields = ["ugf_Anrede", "ugf_Titel", "ugf_Vorname", "ugf_Nachname", "ugf_Strasse", "ugf_hausNummer", "ugf_Plz", "ugf_Ort"];
  totalReqFields = ["kfzSvSelect", "restwert", "wiederbeschaffungswert", "ausfallart"];
  totalAllFields = ["restwert", "wiederbeschaffungswert", "restwertVerkauf", "restwertAngebot", "abmeldekosten", "ausfallart",
    "mietwagenFirma", "mietwagenkosten", "ersatzwagen", "kaufvertragsDatum", "neuwagenPreis", "dauerNutzungsausfalls"];
  restwertVerkauftFields = ["restwertAngebot"];
  totalAusfallartFields = ["ausfallart", "mietwagenFirma", "mietwagenkosten", "ersatzwagen", "kaufvertragsDatum", "neuwagenPreis", "dauerNutzungsausfalls"];
  mietwagenFields = ["mietwagenFirma", "mietwagenkosten"];
  totalNutzungausfallFields = ["dauerNutzungsausfalls"];
  ersatzwagenFields = ["kaufvertragsDatum", "neuwagenPreis"];
  repReqFields = ["artAbrechnung", "ausfallart"];
  repAllFields = ["wertminderung", "artAbrechnung", "nettoreparaturkosten", "bruttoreparaturkosten", "ausfallart", "mietwagenFirma", "mietwagenkosten", "beginReparartur", "endReparartur"];
  repAusfallartFields = ["ausfallart", "mietwagenFirma", "mietwagenkosten", "beginReparartur", "endReparartur"];
  repNutzungausfallFields = ["beginReparartur", "endReparartur"];
  abrechnungFields = [["artAbrechnung", "nettoreparaturkosten", "bruttoreparaturkosten"], ...this.mietwagenFields, ...this.repNutzungausfallFields];
  fictivFields = ["nettoreparaturkosten"];
  konkretReqFields = ["bruttoreparaturkosten", "ausfallart"];
  konkretResetFields = [...["bruttoreparaturkosten", "ausfallart"], ...this.repNutzungausfallFields];
  commonNutzungausfallReqFields = ["fahrzeugklasse", "erstzulassung"];
  today = new Date();
  tomorrow = new Date();
  options: UploaderOptions;
  // files: UploadFile[];
  files: FileDetails[] = [];
  uploadInput: EventEmitter<UploadInput>;
  filesFormData = new FormData();
  fff = FileFormFields;
  preview: string;
  rightHeight = 0;
  showCommentSection = false;
  public innerHeight = 0;

  //#endregion

  @ViewChild('leftPortion') leftPortion: ElementRef;

  constructor(
    public datepipe: DatePipe,
    private cd: ChangeDetectorRef,
    public router: Router,
    private route: ActivatedRoute,
    private mandateService: MandateService,
    private mandantenService: MandantenService,
    private location: Location,
    private insurerService: InsurerService,
    private wPartnerService: WerkstattPartnerService,
    private kfzPartnerService: KfzpartnerService,
    private sharedService: SharedService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private commonFunctions: CommonFunctions,
    public helpers: Helpers,
    public fb: FormBuilder,
  ) {
    // this.mandatForm.addControl(
    //   'gebührensatz',
    //   new FormControl(1, Validators.required)
    // );
    this.today.setDate(this.today.getDate());
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.getTypes();
    console.log(this.gebuehrensatzlist)
    // this.subs.sink = this.mandateService.listFahrzeugklasse()
    //   .subscribe(
    //     (fahrzeugklasse: Fahrzeugklasse[]) => { this.updateFahrzeugklasse(fahrzeugklasse); }
    //   );
    this.subs.sink = this.insurerService.listInsurers()
      .subscribe(
        (insurers) => { this.updateInsurerNames(insurers); console.log(insurers)}
      );
    this.subs.sink = this.wPartnerService.listPartners()
      .subscribe(
        (partners: WerkstattPartner[]) => {
          this.wPartners = partners; this.populateDetails(this.wPartners, 'werkstatt');
          console.log(this.wPartners)
        }
      );
    this.subs.sink = this.kfzPartnerService.listPartners()
      .subscribe(
        (partners: KFZPartner[]) => {
          this.kfzPartners = partners; this.populateDetails(this.kfzPartners, 'kfz');
        }
      );
    this.subs.sink = this.route.paramMap.subscribe(params => {
      this.mandatID = params.get('mandatID');
      this.mandantID = params.get('mandantID');

      if (this.mandantID && this.mandatID) {
        this.pageMode = 'view';
        this.subs.sink = this.mandateService.viewOneMandat(this.mandatID)
          .subscribe(
            (mandat: Mandat) => {
              if (mandat.werkstatt != null) {
                this.werkstattIsSet = true;
              }
              if (mandat.kfzSachverstaendiger != null || mandat.kfzAnsprechpartner != null) {
                this.svIsSet = true;
              }
              console.log(mandat);
              this.mandat = mandat; this.claimed = mandat.claimed;
              this.subs.sink = this.mandantenService.viewOneMandant(this.mandantID)
                .subscribe(
                  (mandant: Mandant) => {
                    this.mandant = mandant; console.log(mandant);
                    this.proceed();
                  }
                );
            }
          );

      } else if (this.mandantID && !this.mandatID) {
        this.subs.sink = this.mandantenService.viewOneMandant(this.mandantID)
          .subscribe(
            (mandant: Mandant) => { this.mandant = mandant; console.log(mandant); this.proceed(); }
          );
      }
    });
  }

  getTypes() {
    this.subs.sink = this.sharedService.getTypes().subscribe(
      types => {
        types.titel.forEach(r => {
          this.titels.push(r);
        });
        types.anrede.forEach(r => {
          this.anreden.push(r);
        });
        this.ausfallarts = types.ausfallart;
        types.gebuehrensatz.forEach(r => {
          this.gebuehrensatzlist.push(r);
        });
        types.grundfahrt.forEach(r => {
          this.grundfahrte.push(r);
        });
        types.schuldfrage.forEach(r => {
          this.begruendungSchuldfragelist.push(r);
        });
        types.status.forEach(r => {
          this.statuses.push(r);
        });
        types.fahrzeugKlasse.forEach(r => {
          this.fahrzeuggruppen.push(r);
        });
        types.zeugen_rolle.forEach(r => {
          this.zeugen_rolle.push(r);
        });
        this.objectTypes = types.objectTypes;
      },
      err => {
        this.router.navigate(['/mandanten/list']);
      }
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.saved || this.created || !this.mandatForm.dirty) {
      if (!this.created && this.pageMode === 'edit') {
        this.clearLock();
      }
      return true;
    } else {
      if (confirm('Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?')) {
        this.clearLock();
        return true;
      } else {
        return false;
      }
    }
  }

  ngOnDestroy() {
    this.clearLock();
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  clearLock() {
    this.subs.sink = this.sharedService.clearLock(this.mandatID)
      .subscribe(data => {
      });
  }



  //#region ngOnInit reactive form creation
  ngOnInit() {
    // moment.locale('de');
    if(localStorage.getItem('userId')){
      this.userId = localStorage.getItem('userId');
    }
    this.mandatForm = this.commonFunctions.getMandatForm(
      this.statuses, this.anreden, this.titels, this.insurerNames, this.sachbearbeiter, this.grundfahrte, this.begruendungSchuldfragelist,
      this.fahrzeuggruppen, this.disableFahrer, this.werkstatt, this.werkstattAnsprechpartner, this.kfzSachverstaendiger,
      this.kfzAnsprechpartner, this.gebuehrensatzlist, this.forderung, this.z_forderung
    )
    console.log(this.mandatForm.value);

    /* this.subs.sink = this.lfType.valueChanges.subscribe(type => {
      if (type === 2) { // 'Unternehmen'
        this.mandatForm.get('lf_anrede').setValue(this.anreden[0].id);
        this.mandatForm.get('lf_titel').setValue(this.titels[0].id);
        this.mandatForm.get('lf_vorname').reset();
        this.mandatForm.get('lf_nachname').reset();
        this.mandatForm.get('lf_anrede').clearValidators();
        this.mandatForm.get('lf_titel').clearValidators();
        this.mandatForm.get('lf_vorname').clearValidators();
        this.mandatForm.get('lf_nachname').clearValidators();
        this.mandatForm.get('lf_anrede').updateValueAndValidity();
        this.mandatForm.get('lf_titel').updateValueAndValidity();
        this.mandatForm.get('lf_vorname').updateValueAndValidity();
        this.mandatForm.get('lf_nachname').updateValueAndValidity();
      } else {
        // this.mandatForm.get('lf_anrede').setValidators(this.customRequired.bind(this));
        this.mandatForm.get('lf_firmenName').reset();
        this.mandatForm.get('lf_firmenName').clearValidators();
        this.mandatForm.get('lf_firmenName').updateValueAndValidity();
        this.mandatForm.get('lf_vertragsNummer').reset();
        this.mandatForm.get('lf_vertragsNummer').clearValidators();
        this.mandatForm.get('lf_vertragsNummer').updateValueAndValidity();
      }
    }); */

    /* this.subs.sink = this.ugInsurerType.valueChanges.subscribe(type => {
      if (type === 2) { // 'Unternehmen'
        this.mandatForm.get('ugf_anrede').setValue(this.anreden[0].id);
        this.mandatForm.get('ugf_titel').setValue(this.titels[0].id);
        this.mandatForm.get('ugf_vorname').reset();
        this.mandatForm.get('ugf_nachname').reset();
        this.mandatForm.get('ugf_anrede').clearValidators();
        this.mandatForm.get('ugf_titel').clearValidators();
        this.mandatForm.get('ugf_vorname').clearValidators();
        this.mandatForm.get('ugf_nachname').clearValidators();
        this.mandatForm.get('ugf_anrede').updateValueAndValidity();
        this.mandatForm.get('ugf_titel').updateValueAndValidity();
        this.mandatForm.get('ugf_vorname').updateValueAndValidity();
        this.mandatForm.get('ugf_nachname').updateValueAndValidity();
      } else {
        // this.mandatForm.get('ugf_anrede').setValidators(this.customRequired.bind(this));
        this.mandatForm.get('ugf_firmenName').reset();
        this.mandatForm.get('ugf_firmenName').clearValidators();
        this.mandatForm.get('ugf_firmenName').updateValueAndValidity();
        this.mandatForm.get('ugf_ustId').reset();
        this.mandatForm.get('ugf_ustId').clearValidators();
        this.mandatForm.get('ugf_ustId').updateValueAndValidity();
      }
    }); */

    if (browserRefresh) {
      this.clearLock();
    }

    /*     this.filteredOptions = this.mandatForm.get('unfallgegner.haftpflichtversicherung.name').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        ); */
    // this.nettorep.valueChanges.subscribe(val => {
    //   this.mandatForm.get('forderung').setValue(this.calcForderung());
    // });
    // this.z_nettorep.valueChanges.subscribe(val => {
    //   // this.calcZForderung();
    //   // console.log(this.calcForderung());
    //   this.mandatForm.get('z_forderung').setValue(this.calcZForderung());
    //   // this.mandatForm.get('z_forderung').setValue(this.z_forderung);
    // });
    // combineLatest()
    // this.mandatForm.valueChanges.subscribe(val => {
    //   if (this.pageMode === 'edit') {
    //     // const total = this.calcForderung();
    //     // this.mandatForm.get('forderung').setValue(this.forderung);

    //   }
    // });
  }
  // get nettorep() {
  //   return this.mandatForm.get('nettoreparaturkosten') as FormControl;
  // }

  // get z_nettorep() {
  //   return this.mandatForm.get('z_nettoreparaturkosten') as FormControl;
  // }

  get abschleppkosten() {
    return this.mandatForm.get('abschleppkosten') as FormControl;
  }

  // get lfType() {
  //   return this.mandatForm.get('lf_Type') as FormControl;
  // }

  get ugInsurerType() {
    return this.mandatForm.get('ugInsurerType') as FormControl;
  }

  /*   private _filter(value: string): string[] {
      const filterValue = value.toLowerCase();

      return this.insurerNames.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    } */

  createZeugen(): FormGroup {
    return new FormGroup({
      id: new FormControl(null, Validators.nullValidator),
      anrede: new FormControl(this.anreden[0].id, [Validators.required, this.commonFunctions.customRequiredById.bind(this)]),
      titel: new FormControl(this.titels[0].id, Validators.nullValidator),
      vorname: new FormControl(null, Validators.required),
      nachname: new FormControl(null, [Validators.required, this.checkduplicateZeugen.bind(this)]),
      rolle: new FormControl(this.zeugen_rolle[0].id, Validators.nullValidator),
      strasse: new FormControl(null, Validators.nullValidator),
      hausNummer: new FormControl(null, Validators.nullValidator),
      plz: new FormControl(null, [Validators.nullValidator, Validators.pattern('[0-9]*')]),
      ort: new FormControl(null, Validators.nullValidator),
      telefonNummer: new FormControl(null, [Validators.nullValidator, Validators.pattern('[0-9]*'), Validators.maxLength(30)]),
      mandatId: new FormControl(null, Validators.nullValidator),
    });
  }
  checkduplicateZeugen(control: AbstractControl): { [key: string]: any } | null {
    const existingArray = this.mandatForm.get('zeugen').value;
    return this.helpers.checkduplicate(control, existingArray);
  }

  addZeugen() {
    this.zeugen = this.mandatForm.get('zeugen') as FormArray;
    this.zeugen.insert(0, this.createZeugen());
  }

  addSachschaeden() {
    this.sachschaeden = this.mandatForm.get('sachschaeden') as FormArray;
    // const length = this.sachschaeden.controls.unshift(this.createSachschaeden());
    this.sachschaeden.insert(0, this.commonFunctions.createSachschaeden());
  }

  addBearbeitung() {
    this.bearbeitung = this.commonFunctions.addBearbeitung(this.mandatForm.get('bearbeitung') as FormArray);
    this.disableBearbeitung = true;
  }

  deleteZeugen(i: number) {
    this.zeugen = this.mandatForm.get('zeugen') as FormArray;
    this.zeugen.removeAt(i);
    this.zeugen.markAsDirty();
    this.zeugen.markAsTouched();
  }

  deleteSchaeden(i: number) {
    this.sachschaeden = this.mandatForm.get('sachschaeden') as FormArray;
    this.sachschaeden.removeAt(i);
    this.sachschaeden.markAsDirty();
    this.sachschaeden.markAsTouched();
  }

  //#endregion

  getNameOrFirma(mandant) {
    return this.helpers.getNameOrFirma(mandant, this.anreden, this.titels);
  }

  getZRolleName(zeugen) {
    return this.helpers.getAnyValue(zeugen.rolle, this.zeugen_rolle);
  }

  // Proceed aftet ngOnInit finishes subscribe

  proceed() {
    if (this.mandatID && this.router.url.includes('mandat')) {
      this.viewMandat();
    } else if (
      this.mandantID &&
      this.router.url.includes('create')
    ) {
      this.router.navigate(['/mandate/create', this.mandantID]);
    } else {
      this.router.navigate(['/mandate/list']);
    }
  }

  updateInsurerNames(insurers) {
    this.insurers = insurers;
    for (const insurer of insurers) {
      this.insurerNames.push({ id: insurer.id, value: insurer.insurerName, viewValue: insurer.insurerName });
    }
  }
  populateDetails(partners, type) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < partners.length; i++) {
      const name = this.helpers.getNameOrFirma(partners[i], this.anreden, this.titels);
      if (type === 'werkstatt') {
        this.werkstatt.push({ value: name, viewValue: name, id: partners[i].id });
      } else if (type === 'kfz') {
        this.kfzSachverstaendiger.push({ value: name, viewValue: name, id: partners[i].id });
      }
    }
  }
  //#region Mandat form toggle handling
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.manageRightHeight();
  }

  manageRightHeight(){
    this.rightHeight = this.leftPortion.nativeElement.offsetHeight - 10;
    this.showCommentSection = true;
    this.cd.detectChanges();
  }
  
  ngAfterContentInit(): void {
    // this.percentage = ((this.mandatForm.get('z_forderung').value / this.mandatForm.get('forderung').value) * 100).toFixed(2);
  }

  leasingFZToggle() {
    const checked = this.mandatForm.get('leasingfahrzeug').value;
    const type = 2; // 'Unternehmen'
    if (!checked) {
      this.mandatForm = this.commonFunctions.resetLeasingFZFields(this.mandatForm, type, this.anreden[0].id,
        this.titels[0].id, this.leasingFZFields1, this.leasingFZFields2);
    } else {
      this.mandatForm = this.commonFunctions.setLeasingFZValidations(this.mandatForm, this.leasingFZFields2);
    }
  }

  leasingFZRadio() {
    const value = this.mandatForm.get('lf_Type').value;
    const checked = this.mandatForm.get('leasingfahrzeug').value;
    if (checked) {
      this.mandatForm = this.commonFunctions.handleLeasingFZTypes(this.mandatForm, value, this.anreden[0].id,
        this.titels[0].id, this.leasingFZFields1, this.leasingFZFields2);
    }
  }

  mandantFahrerToggle() {
    const checked = this.mandatForm.get('fahrer').value;
    if (!checked) {
      this.mandatForm = this.commonFunctions.setMandantFahrerValidations(this.mandatForm, checked, this.anreden[0].id,
        this.titels[0].id, this.mandantFahrerFields);
    } else {
      this.mandatForm = this.commonFunctions.setMandantFahrerValidations(this.mandatForm, checked, this.anreden[0].id,
        this.titels[0].id, this.mandantFahrerFields);
    }
  }

  unfalltextToggle() {
    this.mandatForm.get('unfallhergang').reset();
  }

  ugFahrerToggle() {
    const checked = this.mandatForm.get('ugFahrer').value;
    if (!checked) {
      this.mandatForm = this.commonFunctions.setUGFahrerValidations(this.mandatForm, checked, this.anreden[0].id,
        this.titels[0].id, this.ugFahrerFields);
    } else {
      this.mandatForm = this.commonFunctions.setUGFahrerValidations(this.mandatForm, checked, this.anreden[0].id,
        this.titels[0].id, this.ugFahrerFields);
    }
  }

  schaedenTypeRadio(eventType) {
    const schaedenType = this.mandatForm.get('schaedenType').value;
    this.mandatForm = this.commonFunctions.schaedenTypeRadio(this.mandatForm, eventType, schaedenType, this.totalReqFields, this.totalAllFields, this.repReqFields, this.repAllFields)
  }

  restwertVerkaufCheck() {
    const checked = this.mandatForm.get('restwertVerkauf').value;
    this.mandatForm = this.commonFunctions.restwertVerkaufCheck(this.mandatForm, checked, this.restwertVerkauftFields);
  }

  ausfallartRadio() {
    const schaedenType = this.mandatForm.get('schaedenType').value;
    const ausfallart = this.mandatForm.get('ausfallart').value;
    if(schaedenType && ausfallart){
      this.mandatForm = this.commonFunctions.ausfallartRadio(this.mandatForm, schaedenType, ausfallart,
        this.mietwagenFields, this.totalNutzungausfallFields, this.repNutzungausfallFields, this.commonNutzungausfallReqFields, this.fahrzeuggruppen[0].id)
    }
  }

  ersatzwagenToggle() {
    const checked = this.mandatForm.get('ersatzwagen').value;
    this.mandatForm = this.commonFunctions.ersatzwagenToggle(this.mandatForm, checked, this.ersatzwagenFields);
  }

  artAbrechnungRadio() {
    const value = this.mandatForm.get('artAbrechnung').value;
    this.mandatForm = this.commonFunctions.artAbrechnungRadio(this.mandatForm, value, this.fictivFields, this.konkretReqFields, this.konkretResetFields);
  }

  partnersWDropdown(event: MatSelectChange) {
    const selected = event.value;
    let ansprechPartner: AnsprechPartner[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.wPartners.length; i++) {
      if (this.wPartners[i].id === selected.id) {
        ansprechPartner = this.wPartners[i].ansprechPartners;
      }
    }
    this.werkstattAnsprechpartner = [
      { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
    ];
    for (const j of ansprechPartner) {
      const name = this.helpers.getNameOrFirma(j, this.anreden, this.titels);
      this.werkstattAnsprechpartner.push({
        value: name,
        viewValue: name,
        id: j.id
      });
    }
    this.mandatForm.patchValue({
      wpartnerId: selected.id,
      werkstatt: selected.value,
      werkstattAnsprechpartner: '',
      wAPSelect: this.werkstattAnsprechpartner[0],
      wAPartnerId: 0
    });
  }

  aPWDropdown(event: MatSelectChange) {
    const selected = event.value;
    this.mandatForm.patchValue({
      wAPartnerId: selected.id,
      werkstattAnsprechpartner: selected.value
    });
  }

  partnersWDropdownPopulate(id) {
    if (!id) {
      return;
    }
    let ansprechPartner: AnsprechPartner[] = [];
    let partnerIndex = 0;
    let aPartnerIndex = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.wPartners.length; i++) {
      if (this.wPartners[i].id === id) {
        ansprechPartner = this.wPartners[i].ansprechPartners;
        partnerIndex = i + 1; // because of extra item in werkstatt array
      }
    }
    this.werkstattAnsprechpartner = [
      { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
    ];
    for (const j of ansprechPartner) {
      const name = this.helpers.getNameOrFirma(j, this.anreden, this.titels);
      this.werkstattAnsprechpartner.push({
        value: name,
        viewValue: name,
        id: j.id
      });
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 1; i < this.werkstattAnsprechpartner.length; i++) {
      if (this.werkstattAnsprechpartner[i].id === this.mandat.wAPartnerId) {
        aPartnerIndex = i; // because of extra item in werkstattAP array
      }
    }
    this.mandatForm.patchValue({
      werkstattSelect: this.werkstatt[partnerIndex],
      wAPSelect: this.werkstattAnsprechpartner[aPartnerIndex],
      werkstatt: this.werkstatt[partnerIndex].value,
      werkstattAnsprechpartner: this.werkstattAnsprechpartner[aPartnerIndex].value
    });
  }

  partnersKfzDropdown(event: MatSelectChange) {
    const selected = event.value;
    let ansprechPartner: KFZAnsprechPartner[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.kfzPartners.length; i++) {
      if (this.kfzPartners[i].id === selected.id) {
        ansprechPartner = this.kfzPartners[i].ansprechPartners;
      }
    }
    this.kfzAnsprechpartner = [
      { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
    ];
    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < ansprechPartner.length; j++) {
      const name = this.helpers.getNameOrFirma(ansprechPartner[j], this.anreden, this.titels);
      this.kfzAnsprechpartner.push({
        value: name,
        viewValue: name,
        id: ansprechPartner[j].id
      });
    }
    this.mandatForm.patchValue({
      kfzpartnerId: selected.id,
      kfzSachverstaendiger: selected.value,
      kfzAnsprechpartner: '',
      kfzAPSelect: this.kfzAnsprechpartner[0],
      kfzAPartnerId: 0
    });
  }

  aPKFZDropdown(event: MatSelectChange) {
    const selected = event.value;
    this.mandatForm.patchValue({
      kfzAPartnerId: selected.id,
      kfzAnsprechpartner: selected.value,
    });
  }

  partnersKfzDropdownPopulate(id) {
    if (!id) {
      return;
    }
    let ansprechPartner: KFZAnsprechPartner[] = [];
    let partnerIndex = 0;
    let aPartnerIndex = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.kfzPartners.length; i++) {
      if (this.kfzPartners[i].id === id) {
        ansprechPartner = this.kfzPartners[i].ansprechPartners;
        partnerIndex = i + 1; // because of extra item in werkstatt array
      }
    }
    this.kfzAnsprechpartner = [
      { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
    ];
    // tslint:disable-next-line: prefer-for-of
    for (let j = 0; j < ansprechPartner.length; j++) {
      const name = this.helpers.getNameOrFirma(ansprechPartner[j], this.anreden, this.titels);
      this.kfzAnsprechpartner.push({
        value: name,
        viewValue: name,
        id: ansprechPartner[j].id
      });
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 1; i < this.kfzAnsprechpartner.length; i++) {
      if (this.kfzAnsprechpartner[i].id === this.mandat.kfzAPartnerId) {
        aPartnerIndex = i; // because of extra item in kfzAP array
      }
    }
    this.mandatForm.patchValue({
      kfzSvSelect: this.kfzSachverstaendiger[partnerIndex],
      kfzAPSelect: this.kfzAnsprechpartner[aPartnerIndex],
      kfzSachverstaendiger: this.kfzSachverstaendiger[partnerIndex].value,
      kfzAnsprechpartner: this.kfzAnsprechpartner[aPartnerIndex].value
    });
  }

  // Insurer dropdown change

  insurerDropdown(event: MatSelectChange) {
    const selected = event.value;
    let sachbearbeiter: Sachbearbeiter[] = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.insurers.length; i++) {
      if (this.insurers[i].id === selected.id) {
        sachbearbeiter = this.insurers[i].sachbearbeiters;
      }
    }
    this.sachbearbeiter = [
      { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
    ];
    for (const j of sachbearbeiter) {
      const name = this.helpers.getNameOrFirma(j, this.anreden, this.titels);
      this.sachbearbeiter.push({
        value: name,
        viewValue: name,
        id: j.id
      });
    }
    this.mandatForm.patchValue({
      insurerId: selected.id,
      hvName: selected.value,
      sachbearbeiter: '',
      sachbearbeiterSelect: this.sachbearbeiter[0],
      sachbearbeiterId: 0
    });
  }

  sachbearbeiterDropdown(event: MatSelectChange) {
    const selected = event.value;
    this.mandatForm.patchValue({
      sachbearbeiterId: selected.id,
      sachbearbeiter: selected.value
    });
  }

  sachbearbeiterDropdownPopulate(id) {
    if (!id) {
      return;
    }
    let sachbearbeiter: Sachbearbeiter[] = [];
    let insurerIndex;
    let sBIndex;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.insurers.length; i++) {
      if (this.insurers[i].id === id) {
        sachbearbeiter = this.insurers[i].sachbearbeiters;
        insurerIndex = i + 1; // because of extra item in insurer array
      }
    }
    this.sachbearbeiter = [
      { value: '', viewValue: '-- Bitte auswählen --', id: 0 }
    ];
    for (const j of sachbearbeiter) {
      const name = this.helpers.getNameOrFirma(j, this.anreden, this.titels);
      this.sachbearbeiter.push({
        value: name,
        viewValue: name,
        id: j.id
      });
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.sachbearbeiter.length; i++) {
      if (this.sachbearbeiter[i].id === this.mandat.sachbearbeiterId) {
        sBIndex = i; // because of extra item in sachbearbeiter array
      }
    }
    this.mandatForm.patchValue({
      insurerSelect: this.insurerNames[insurerIndex],
      sachbearbeiterSelect: this.sachbearbeiter[sBIndex],
      hvName: this.insurerNames[insurerIndex].value,
      sachbearbeiter: this.sachbearbeiter[sBIndex].value
    });
  }
  //#endregion

  // set kostenNutzungsausfalls value based on vehicle class
  kostenNutzungsausfalls(value: number) {
    this.mandatForm.patchValue({
      fahrzeugSchaeden: {
        kostenNutzungsausfalls: value * 10
      }
    });
  }

  viewMandat() {
    this.pageMode = 'view';
    this.mandatForm.disable();
    this.patchMandatForm();
  }

  patchMandatForm() {
    this.mandatForm.patchValue(this.mandat);
    if(this.mandatForm.get('gebuehrensatz').value == 0){
      this.mandatForm.patchValue({'gebuehrensatz' : 1.3})
    }
    console.log("PATCH MANDATE!   ",this.mandatForm.value)
    this.zeugen1 = this.mandat.zeugens;
    this.sachschaeden1 = this.mandat.sachschaedens;
    this.bearbeitung1 = this.mandat.bearbeitungs;
    this.updateZeugenDatasource();
    this.updateSchadenDatasource();
    this.updateABelegDatasource();
    this.mandatForm.setControl('bearbeitung', this.commonFunctions.setexistingBearbeitung(this.mandat.bearbeitungs)),
      this.bearbeitung = this.mandatForm.get('bearbeitung') as FormArray;
    this.bearbeitung.controls.forEach(control => {
      control.get('comments').disable();
    });
    this.mandatForm.patchValue({
      /* unfallDatum: this.datepipe.transform(this.mandat.unfallDatum, 'yyyy-MM-dd'),
      erstzulassung: this.datepipe.transform(this.mandat.erstzulassung, 'yyyy-MM-dd'),
      restwertAngebot: this.datepipe.transform(this.mandat.restwertAngebot, 'yyyy-MM-dd'),
      kaufvertragsDatum: this.datepipe.transform(this.mandat.kaufvertragsDatum, 'yyyy-MM-dd'), */
      unfallDatum: this.mandat.unfallDatum ? moment(this.mandat.unfallDatum).format() : null,
      erstzulassung: this.mandat.erstzulassung ? moment(this.mandat.erstzulassung).format() : null,
      restwertAngebot: this.mandat.restwertAngebot ? moment(this.mandat.restwertAngebot).format() : null,
      kaufvertragsDatum: this.mandat.kaufvertragsDatum ? moment(this.mandat.kaufvertragsDatum).format() : null,
      // beginReparartur: this.datepipe.transform(this.mandat.beginReparartur, 'yyyy-MM-dd'),
      // endReparartur: this.datepipe.transform(this.mandat.endReparartur, 'yyyy-MM-dd'),
      beginReparartur: this.mandat.beginReparartur ? moment(this.mandat.beginReparartur).format() : null,
      endReparartur: this.mandat.endReparartur ? moment(this.mandat.endReparartur).format() : null,
    });
    if (this.mandat.insurerId !== '') {
      this.sachbearbeiterDropdownPopulate(this.mandat.insurerId);
    } else {
      this.sachbearbeiterDropdownPopulate('');
    }
    this.partnersWDropdownPopulate(this.mandat.wpartnerId);
    this.partnersKfzDropdownPopulate(this.mandat.kfzpartnerId);
    // this.sachbearbeiterDropdownPopulate(this.mandat.insurerId);
    this.setValidatorsAfterPatch();
  }

  setValidatorsAfterPatch() {
    this.leasingFZToggle();
    this.leasingFZRadio();
    this.mandantFahrerToggle();
    this.ugFahrerToggle();
    this.schaedenTypeRadio('patch');
    this.restwertVerkaufCheck();
    this.ausfallartRadio();
    this.ersatzwagenToggle();
    this.artAbrechnungRadio();
  }

  //#region Populate Mandat form
  editMandat(mandat: Mandat) {
    if (this.pageMode === 'view') {
      this.subs.sink = this.sharedService.checkLock('mandat', this.mandatID)
        .subscribe(data => {
          this.pageMode = 'edit';
          // this.patchMandatForm();
          this.mandatForm.enable();
        }, error => {
          this.createError(error);
          this.snackbarService.open(this.errMsg.message, 'failure');
        });
    } else {
      this.saveMandat();
    }
  }

  get sachschaedenData() {
    return this.mandatForm.get('sachschaeden') as FormArray;
  }

  get zeugenData() {
    return this.mandatForm.get('zeugen') as FormArray;
  }

  get bearbeitungData() {
    return this.mandatForm.get('bearbeitung') as FormArray;
  }

  //#endregion

  //#region Validations

  get validations() {
    return this.mandatForm.controls;
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.mandatForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  //#region Create Mandat functions
  createMandat() {
    if (!this.mandatForm.valid) {
      this.validateAllFormFields(this.mandatForm);
      console.log('invalid', this.findInvalidControls());
      return;
    } else {
      this.sharedService.progress.next(true);
      this.calcForderung();
      // this.mandatForm.get('svgutachten').updateValueAndValidity();
      console.log(this.mandatForm.value);
      this.filesFormData.append('mandat', JSON.stringify(this.mandatForm.value));
      this.subs.sink = this.mandateService
        .createMandat(JSON.stringify(this.mandatForm.value),
          this.files, this.mandant.id)
        .subscribe(
          (data: Mandat) => {
            this.created = true;
            console.log(data);
            this.router.navigate(['/mandate/list']);
            this.snackbarService.open(`Fall ${data.id} wurde angelegt.`, 'success');
            /* if (this.mandatForm.get('svgutachten').value) {
              const formData1 = new FormData();
              formData1.append('file', this.svgutachtenFile);
              this.mandateService.upload(formData1, data.id).subscribe(
                (res) => {
                  if (res.message === 100) { // 100% uploaded
                    console.log(res);
                    this.uploadResponse = res;
                    this.created = true;
                    console.log(data);
                    this.location.back();
                    this.snackbarService.open(`Fall ${data.id} wurde angelegt.`, 'success');
                  }
                },
                (err) => this.error = err
              );
            } */
          },
          error => {
            {
              console.error(error);
              this.createError(error);
              this.snackbarService.open(this.errMsg.message, 'failure');
            }
          }
        );
      this.sharedService.progress.next(false);
      /*       const formData = new FormData();
            formData.append('file', this.polizeiberichtFile);
            if (this.mandatForm.get('unfall.polizeibericht').value) {
              this.mandateService.upload(formData).subscribe(
                (res) => [this.uploadResponse = res],
                (err) => this.error = err
              );
            }

            if (this.mandatForm.get('unfall.svgutachten').value) {
              const formData1 = new FormData();
              formData1.append('file', this.svgutachtenFile);

              this.mandateService.upload(formData1).subscribe(
                (res) => [this.uploadResponse = res],
                (err) => this.error = err
              );
            }

            if (this.mandatForm.get('unfall.arztlichesgutachten').value) {

              const formData2 = new FormData();
              formData2.append('file', this.arztlichesgutachtenFile);

              this.mandateService.upload(formData2).subscribe(
                (res) => [this.uploadResponse = res],
                (err) => this.error = err
              );
            } */
    }
  }
  //#endregion

  removeFailureFiles(){
    const deleteFileType = this.mandatForm.get('ausfallart').value === '1' ? this.fff.kaufvertrag : this.fff.mietwagenrechnung;
    if(this.filesFormData.get(deleteFileType))
      this.filesFormData.delete(deleteFileType);

    if(~this.files.findIndex(y => y.formField == deleteFileType))
      this.files.splice(this.files.findIndex(y => y.formField == deleteFileType), 1)
  }

  //#region Save Mandat functions
  saveMandat() {
    this.ausfallartRadio();
    this.removeFailureFiles();
    if (!this.mandatForm.valid) {
      this.validateAllFormFields(this.mandatForm);
      console.log('invalid', this.findInvalidControls());
      return;
    } else {
      this.sharedService.progress.next(true);
      this.calcForderung();
      this.calcZForderung();
      console.log(this.mandatForm.value);
      /* const payload = { mandat: this.mandatForm.value, files: this.files };
      const formData1 = new FormData();
      const formData2 = new FormData();
      formData1.append('file', this.svgutachtenFile);
      this.filesFormData.set('mandat', JSON.stringify(this.mandatForm.value)); */
      // formData2.append('mandat', JSON.stringify(this.mandatForm.value));
      // formData2.append('svgutachten', this.files[0].file);
      // formData2.append('fileList', JSON.stringify(this.files));
      // console.log(formData2);
      // this.files[0].file.forEach(fn => console.log(fn));
      /* this.mandateService.upload(this.filesFormData, this.mandatID).subscribe(
        (res) => {
          if (res.message === 100) { // 100% uploaded
            console.log(res);
            this.uploadResponse = res;
          }
        },
        (err) => this.error = err
      ); */
      this.subs.sink = this.mandateService
        .saveMandat(
          JSON.stringify(this.mandatForm.value),
          this.files,
          this.mandatID, this.mandant.id
        )
        .subscribe(
          (data) => {
            this.subs.sink = this.mandateService.viewOneMandat(this.mandatID).subscribe((mandat: Mandat) => {
              this.saved = true; this.mandat = mandat;
              console.log(this.mandat);
              this.viewMandat(); this.clearLock();
              this.snackbarService.open(`Mandat ${this.mandatID} wurde gespeichert`, 'success');
            });
          },
          error => {
            {
              console.error(error);
              this.createError(error);
              this.snackbarService.open(this.errMsg.message, 'failure');
            }
          }
        );
      this.sharedService.progress.next(false);
    }
  }
  //#endregion

  // Delete Mandat by ID
  deleteMandat() {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.sharedService.progress.next(true);
      this.subs.sink = this.mandateService.deleteMandat(this.mandatID)
        .subscribe(data => {
          if (data) {
            this.clearLock();
            this.router.navigate(['/mandate/list']);
          }
        });
      this.sharedService.progress.next(false);
    }
  }

  //#region Handle zeugen table
  newZeugenModal(zeugenTemplate) {
    this.zeugenEditFlag = false;
    this.zeugenAddFlag = true;
    this.zeugenFormData = this.createZeugen();
    this.dialog.open(zeugenTemplate, {
      width: 'auto', disableClose: true
    });
  }

  editZeugenModal(zeugenTemplate, row, index) {
    this.zeugenEditFlag = true;
    this.zeugenAddFlag = false;
    this.zeugenFormData = row;
    this.zeugenIndex = index;
    this.dialog.open(zeugenTemplate, {
      width: 'auto', disableClose: true
    });
  }

  onNoClick(): void {
    this.updateZeugenDatasource();
    this.dialog.closeAll();
  }

  newZeugen() {
    if (!this.zeugenFormData.valid) {
      this.validateAllFormFields(this.zeugenFormData);
    } else if (this.zeugenFormData.dirty && this.zeugenFormData.valid) {
      this.zeugen1.push(this.zeugenFormData.value);
      this.updateZeugenDatasource(); this.onNoClick();
    } else {
      alert('No changes made');
    }
  }

  saveZeugen(index) {
    if (!this.zeugenFormData.valid) {
      this.validateAllFormFields(this.zeugenFormData);
    } else if (this.zeugenFormData.dirty && this.zeugenFormData.valid) {
      this.zeugen1[index] = this.zeugenFormData.value;
      this.updateZeugenDatasource(); this.onNoClick();
    } else {
      this.snackbarService.open('No changes made', 'warning');
    }
  }

  deleteAP(index) {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.zeugen1.splice(index, 1);
      this.updateZeugenDatasource();
    }
  }

  updateZeugenDatasource() {
    this.mandatForm.setControl(
      'zeugen',
      this.commonFunctions.setexistingZeugen(this.zeugen1)
    );
    this.zeugenDataSource = new MatTableDataSource((this.mandatForm.get('zeugen') as FormArray).controls);
    this.zeugenDataSource.sort = this.sort;
  }
  //#endregion

  //#region Handle schaden table
  newSchadenModal(schadenTemplate) {
    this.schadenEditFlag = false;
    this.schadenAddFlag = true;
    this.schadenFormData = this.commonFunctions.createSachschaeden();
    this.dialog.open(schadenTemplate, {
      width: 'auto', disableClose: true
    });
  }

  editSchadenModal(schadenTemplate, row, index) {
    this.schadenEditFlag = true;
    this.schadenAddFlag = false;
    this.schadenFormData = row;
    this.schadenIndex = index;
    this.dialog.open(schadenTemplate, {
      width: 'auto', disableClose: true
    });
  }

  onNoClick1(): void {
    this.updateSchadenDatasource();
    this.dialog.closeAll();
  }

  newSchaden() {
    if (!this.schadenFormData.valid) {
      this.validateAllFormFields(this.schadenFormData);
    } else if (this.schadenFormData.dirty && this.schadenFormData.valid) {
      this.sachschaeden1.push(this.schadenFormData.value);
      this.updateSchadenDatasource(); this.onNoClick1();
    } else {
      alert('No changes made');
    }
  }

  saveSchaden(index) {
    if (!this.schadenFormData.valid) {
      this.validateAllFormFields(this.schadenFormData);
    } else if (this.schadenFormData.dirty && this.schadenFormData.valid) {
      this.sachschaeden1[index] = this.schadenFormData.value;
      this.updateSchadenDatasource(); this.onNoClick1();
    } else {
      alert('No changes made');
    }
  }

  deleteSachschaden(index) {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.sachschaeden1.splice(index, 1);
      this.updateSchadenDatasource();
    }
  }

  updateSchadenDatasource() {
    this.mandatForm.setControl(
      'sachschaeden',
      this.commonFunctions.setexistingSachschaeden(this.sachschaeden1)
    );
    this.schadenDataSource = new MatTableDataSource((this.mandatForm.get('sachschaeden') as FormArray).controls);
    this.schadenDataSource.sort = this.sort;
  }
  //#endregion

  //#region Handle Änderungs Beleg table
  newABelegModal(aBelegTemplate) {
    this.aBelegEditFlag = false;
    this.aBelegAddFlag = true;
    this.aBelegFormData = this.commonFunctions.createBearbeitung();
    this.dialog.open(aBelegTemplate, {
      width: 'auto', disableClose: true
    });
  }

  editABelegModal(aBelegTemplate, row, index) {
    this.aBelegEditFlag = true;
    this.aBelegAddFlag = false;
    this.aBelegFormData = row;
    this.aBelegIndex = index;
    this.dialog.open(aBelegTemplate, {
      width: 'auto', disableClose: true
    });
  }

  onNoClick2(): void {
    this.updateABelegDatasource();
    this.dialog.closeAll();
  }

  newABeleg() {
    if (!this.aBelegFormData.valid) {
      this.validateAllFormFields(this.aBelegFormData);
    } else if (this.aBelegFormData.dirty && this.aBelegFormData.valid) {
      this.bearbeitung1.push(this.aBelegFormData.value);
      this.updateABelegDatasource(); this.onNoClick2();
    } else {
      alert('No changes made');
    }
  }

  saveABeleg(index) {
    if (!this.aBelegFormData.valid) {
      this.validateAllFormFields(this.aBelegFormData);
    } else if (this.aBelegFormData.dirty && this.aBelegFormData.valid) {
      this.bearbeitung1[index] = this.aBelegFormData.value;
      this.updateABelegDatasource(); this.onNoClick2();
    } else {
      alert('No changes made');
    }
  }

  deleteABeleg(index) {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.bearbeitung1.splice(index, 1);
      this.updateABelegDatasource();
    }
  }

  updateABelegDatasource() {
    this.mandatForm.setControl(
      'bearbeitung',
      this.commonFunctions.setexistingBearbeitung(this.bearbeitung1)
    );
    this.aBelegDataSource = new MatTableDataSource((this.mandatForm.get('bearbeitung') as FormArray).controls);
    this.aBelegDataSource.sort = this.sort;
  }
  //#endregion

  openDialogMandant(): void {
    this.commonFunctions.openDialogComponenet(CreatemandantComponent, {
      mandantID: this.mandantID
    });
  }

  openDialogInsurer(): void {
    const insurerID = this.mandatForm.get('insurerId').value;
    if (!insurerID) {

    } else {
      this.commonFunctions.openDialogComponenet(CreateinsurerComponent, {
        insurerID: insurerID
      });
    }
  }

  openDialogWerkstatt(): void {
    const partnerID = this.mandatForm.get('wpartnerId').value;
    if (!partnerID) {

    } else {
      this.commonFunctions.openDialogComponenet(CreateWerkstattPartnerComponent, {
        partnerID: partnerID
      });
    }
  }

  openDialogKFZ(): void {
    const partnerID = this.mandatForm.get('kfzpartnerId').value;
    if (!partnerID) {

    } else {
      this.commonFunctions.openDialogComponenet(CreatekfzpartnerComponent, {
        partnerID: partnerID
      });
    }
  }

  createError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }

  openDialogPDFPreview(fieldName): void {
    // File Preview
    if (typeof (FileReader) !== 'undefined') {
      // let reader = new FileReader();
      let foundIndex = this.files.findIndex(y => y.formField == fieldName);
      // reader.readAsArrayBuffer(this.files[0].file);
      if (foundIndex > -1) {
        this.commonFunctions.openDialogComponenet(PdfPreviewComponent, {
          file: this.files[0].file
        });
      } else {
        const fileName = this.mandatForm.get(fieldName).value;
        this.subs.sink = this.mandateService.getFileUpload(this.mandatID, fileName).subscribe(data => {
          this.commonFunctions.openDialogComponenet(PdfPreviewComponent, {
            file: data
          });
        })
      }
    }
  }

  // @ViewChild('takeInput', {static: false}) 
  // InputVar: ElementRef; 

  fileUploadClick(fieldName) {
    let foundIndex = this.files.findIndex(y => y.formField == fieldName);
    if (foundIndex > -1) this.files.splice(foundIndex, 1);
    if (fieldName === this.fff.polizeibericht) {
      this.mandatForm.patchValue({
        polizeibericht: false
      });
    } else if (fieldName === this.fff.vollmacht) {
      this.mandatForm.patchValue({
        vollmacht: false
      });
    } else if (fieldName === this.fff.svgutachten) {
      this.mandatForm.patchValue({
        svgutachten: false
      });
    }
    // this.InputVar.nativeElement.value = "";
    // reset file form control name in UI 
    // this.mandatForm.patchValue({
    //   svgutachten: ''
    // });
    let element: HTMLElement = document.getElementById(fieldName) as HTMLElement;
  }

  removeFile(fieldName, element) {
    let foundIndex = this.files.findIndex(y => y.formField == fieldName);
    if (foundIndex > -1) this.files.splice(foundIndex, 1);
    element.value = '';
    this.mandatForm.patchValue({
      [fieldName]: null
    });
    if (fieldName === this.fff.polizeibericht) {
      this.mandatForm.patchValue({
        polizeibericht: false
      });
    } else if (fieldName === this.fff.vollmacht) {
      this.mandatForm.patchValue({
        vollmacht: false
      });
    } else if (fieldName === this.fff.svgutachten) {
      this.mandatForm.patchValue({
        svgutachten: false
      });
    }
    console.log(this.mandatForm.value)
  }
  showFileOptions(fieldName): boolean {
    let foundIndex = this.files.findIndex(y => y.formField == fieldName);
    if (this.pageMode === 'create') return true;
    // else if (!this.files[foundIndex]) return true;
    else if (!this.mandatForm.get(fieldName).value) return true;
    else return false;
  }

  hideFileInput(fieldName): boolean {
    let foundIndex = this.files.findIndex(y => y.formField == fieldName);
    if (this.pageMode === 'create') return false;
    // else if (!this.files[foundIndex]) return false;
    else if (!this.mandatForm.get(fieldName).value) return false;
    else return true;
  }

  downloadFiles(fileName) {
    this.subs.sink = this.mandateService.getFileUpload(this.mandatID, fileName).subscribe(data => {
      saveAs(data, fileName)
    })
  }

  fileUpload(output: UploadOutput, fieldName) {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' }
      // };
      // this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      let foundIndex = this.files.findIndex(y => y.formField == fieldName);
      /* const formData = new FormData();
      formData.append('file', output.file.nativeFile);
      let fileObj = { name: output.file.nativeFile.name, originalName: output.file.nativeFile.name, formField: x, file: output.file.nativeFile }
      if (foundIndex === -1) this.files.push(fileObj);
      else this.files[foundIndex] = fileObj

      this.mandatForm.patchValue({
        svgutachten: output.file.nativeFile
      });
      this.mandatForm.get('svgutachten').updateValueAndValidity() */
      this.filesFormData.set(fieldName, output.file.nativeFile);
      let fileObj = { formField: fieldName, file: output.file.nativeFile };
      if (foundIndex === -1) this.files.push(fileObj);
      else this.files[foundIndex] = fileObj;
      if (fieldName === this.fff.polizeibericht) {
        this.mandatForm.patchValue({
          polizeibericht: true
        });
      } else if (fieldName === this.fff.vollmacht) {
        this.mandatForm.patchValue({
          vollmacht: true
        });
      } else if (fieldName === this.fff.svgutachten) {
        this.mandatForm.patchValue({
          svgutachten: true
        });
      }
      /* this.mandatForm.patchValue({
        fieldName: fieldName + '.pdf'
      }); */
      // console.log(this.files);
      /* 
      let reader = new FileReader();
      const file = output.file.nativeFile;
      reader.readAsDataURL(file);
      console.log(reader)
      this.mandatForm.patchValue({
        svgutachten: reader.result
      });
      this.cd.markForCheck();
      this.mandatForm.get('svgutachten').updateValueAndValidity()
      console.log(this.mandatForm.get('svgutachten').value) */
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      // const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      // this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      // this.files = this.files.filter((file: UploadFile) => file !== output.file);
    }
  }
  fileChange(event) {
    console.log(event)
  }

  //#region Generate and Download PDF
  downloadPDF() {
    this.sharedService.progress.next(true);
    this.subs.sink = this.mandateService.downloadPDF(this.mandatID)
      .subscribe(
        data => { console.log(data), saveAs(data, 'subbu'); },
        error => console.log(error)
      );
    this.sharedService.progress.next(false);
  }
  //#endregion

  generateClaim() {
    // this.subs.sink = this.mandateService.sendClaim(this.mandatID).subscribe(res => {
    //   this.claimed = true; this.mandat = res;
    // });
    this.subs.sink = this.mandateService.downloadPDF(this.mandatID).subscribe(res => {
      saveAs(res, `${this.mandatID}-${new Date()}`);
      /* this.pdfSrc = {
        url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
       }; */
    });
  }
  generateInvoice() {
    this.subs.sink = this.mandateService.honorarClaim(this.mandatID).subscribe(res => {
      this.mandat = res;
    });
  }
  calcForderung() {
    this.forderung = this.mandatForm.get('nettoreparaturkosten').value + this.mandatForm.get('abschleppkosten').value +
      this.mandatForm.get('abmeldekosten').value + this.mandatForm.get('mietwagenkosten').value +
      this.mandatForm.get('standkosten').value;
    // + mandat.abmeldekosten + mandat.abschleppkosten + mandat.mietwagenkosten + mandat.standkosten;
    this.mandatForm.get('forderung').setValue(this.forderung);
    return this.forderung;
  }
  calcZForderung() {
    this.z_forderung = this.mandatForm.get('z_nettoreparaturkosten').value + this.mandatForm.get('z_abschleppkosten').value +
      this.mandatForm.get('z_abmeldekosten').value + this.mandatForm.get('z_mietwagenkosten').value +
      this.mandatForm.get('z_standkosten').value;
    this.mandatForm.get('z_forderung').setValue(this.z_forderung);
    return this.z_forderung;
  }
  calcPerc(): number {
    this.percentage = ((this.mandatForm.get('z_forderung').value / this.mandatForm.get('forderung').value) * 100).toPrecision(3);
    if (this.percentage >= 0 && this.percentage <= 100) {
      return +this.percentage - 2; // -2 to adjust the triangle icon in frontend
    } else {
      this.percentage = 0;
      return -2;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerHeight !== this.innerHeight)
      this.manageRightHeight();
      
    this.innerHeight = event.target.innerHeight;
  }
}

