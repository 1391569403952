import { SharedService } from 'src/app/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { DocsSiteTheme } from 'src/app/models/user';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-applayout',
  templateUrl: './applayout.component.html'
})
export class ApplayoutComponent implements OnInit {
  public progress;
  public mobileQuery: MediaQueryList;
  // @ViewChild('sidenav') sidenav: MatSidenav;

  public title = 'kanzlei';
  public loggedOut = false;
  public userName = localStorage.getItem('user');
  public userDetail;
  public themes: DocsSiteTheme[] = [
    {
      primary: '#673AB7',
      accent: '#FFC107',
      displayName: 'Deep Purple & Amber',
      name: 'deeppurple-amber'
    },
    {
      primary: '#3F51B5',
      accent: '#E91E63',
      displayName: 'Indigo & Pink',
      name: 'indigo-pink'
    },
    {
      primary: '#E91E63',
      accent: '#607D8B',
      displayName: 'Pink & Blue-grey',
      name: 'pink-bluegrey'
    },
    {
      primary: '#9C27B0',
      accent: '#4CAF50',
      displayName: 'Purple & Green',
      name: 'purple-green'
    },
    {
      primary: '#1565c0',
      accent: '#ffc107',
      displayName: 'Blue & Amber',
      name: 'blue-amber'
    }
  ];
  public navListsOptions = [
    {
      name: 'Fälle',
      routeLink: '/dashboard',
      defaultDisplay: true
    },
    {
      name: 'Mandanten',
      routeLink: '/dashboard/clientList',
      defaultDisplay: true
    },
    {
      name: 'Werkstätten',
      routeLink: '/dashboard/list/werkstatten',
      defaultDisplay: false,
      validateField: 'canViewCRS'
    },
    {
      name: 'Sachverständige',
      routeLink: '/dashboard/list/sachverstandige',
      defaultDisplay: false,
      validateField: 'canViewExpert'
    },
    {
      name: 'Versicherungen',
      routeLink: '/dashboard/list/versicherungen',
      defaultDisplay: true
    },
    {
      name: 'Dokumentvorlagen',
      routeLink: '/dashboard/documentList',
      defaultDisplay: true
    },
    // {
    //   name: 'E-Mail-Vorlagen',
    //   routeLink: '/dashboard/templateList',
    //   defaultDisplay: true
    // },
    {
      name: 'Textbausteine',
      routeLink: '/dashboard/textblockList',
      defaultDisplay: true
    },
  ];
  public navLists = [];

  constructor(public router: Router, public appService: AppService,
    private snackbarService: SnackbarService, private sharedService: SharedService, 
    public media: MediaMatcher) {
    /* The viewport is less than, or equal to, 1024(ipad pro) pixels wide */
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');
    this.refreshNavigationOptions();
    this.sharedService.getUserLoginSuccess().subscribe(res => {
      if(res)
        this.refreshNavigationOptions();
    })
  }

  ngOnInit() {
    this.selectTheme(this.sharedService.currentTheme || localStorage.getItem('defaultThemeColour'));
    // if (this.userService.defaultMenuSelected !== undefined && this.userService.defaultMenuSelected !== null
    //   && this.userService.defaultMenuSelected !== '') {
    //   this.userService.defaultMenuSelected = this.userService.defaultMenuSelected;
    // } else {
    //   this.userService.defaultMenuSelected = this.navLists[0].name;
    // }
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   if (this.userService?.loggedIn()) {
  //     if (event.target.innerWidth <= 1024) {
  //       this.sidenav.close();
  //     } else {
  //       this.sidenav.open();
  //     }
  //   }
  // }

  logout() {
    this.sharedService.logoutUser();
    this.sharedService.open('Sie wurden erfolgreich abgemeldet.', 'success');
  }

  selectTheme(selectedthemeName: string) {
    const theme = this.themes.find(currentTheme => currentTheme.name === selectedthemeName);
    if (!theme) {
      return;
    } else {
      this.sharedService.currentTheme = theme.name;
      document.body.className = '';
      document.body.classList.add(`${theme.name}-theme`);
      // this.liveAnnouncer.announce(`${theme.displayName} theme selected.`, 'polite', 3000);
      this.appService.updateTheme(theme.name)
        .subscribe(data => {
          localStorage.setItem('defaultThemeColour', theme.name);
        }, error => {
          console.log(error);
        });
    }
  }

  refreshNavigationOptions() {
    this.userDetail = this.sharedService.userDetail();

    if(this.userDetail)
      this.navLists = this.navListsOptions.filter((option: any) => this.checkSidebarOption(option));
  }

  checkSidebarOption({ defaultDisplay, validateField }) {
      return defaultDisplay ? true : this.userDetail?.permissions.includes(validateField);
  }

  // currentMenu(newValue) {
  //   this.userService.defaultMenuSelected = newValue.name;
  //   localStorage.setItem('defaultMenuSelected', newValue.name);
  // }
}
