import { MatPaginatorIntl } from '@angular/material/paginator';
import { ListWerkstattPartnersComponent } from './components/werkstattpartners/list-werkstatt-partners/list-werkstatt-partners.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './components/userlogin/user.service';
import { DropdownDirective } from '../app/components/shared/dropdown.directive';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { CreatemandantComponent } from './components/mandanten/createmandant/createmandant.component';
import { MandantenService } from './components/mandanten/mandanten.service';
import { AllMaterialModule } from './material-module';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ListmandateComponent } from './components/mandate/listmandate/listmandate.component';
import { MandateService } from './components/mandate/mandate.service';
import { CreatemandatComponent } from './components/mandate/createmandat/createmandat.component';
import { ListmandantenComponent } from './components/mandanten/listmandanten/listmandanten.component';
import { getDeutchPaginatorIntl } from 'src/app/components/shared/deutsch-paginator-intl';
import { CreateinsurerComponent } from './components/insurers/createinsurer/createinsurer.component';
import { ListinsurersComponent } from './components/insurers/listinsurers/listinsurers.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { DatePipe } from '@angular/common';
import { ApplayoutComponent } from './shared/applayout/applayout.component';
import { ConfirmGuard } from './guards/confirm.guard';
import { BnNgIdleService } from 'bn-ng-idle';
import { AlertModule } from './_alert';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import {MatTreeModule} from '@angular/material/tree';
import { MandatsTableComponent } from './components/shared/mandats-table/mandats-table.component';
import { CreateWerkstattPartnerComponent } from './components/werkstattpartners/create-werkstatt-partner/create-werkstatt-partner.component';
import { CreatekfzpartnerComponent } from './components/kfzpartners/createkfzpartner/createkfzpartner.component';
import { ListkfzpartnersComponent } from './components/kfzpartners/listkfzpartners/listkfzpartners.component';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AppalertsComponent } from './components/appalerts/appalerts.component';
import { SnackbarService } from './services/snackbar.service';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { MessageService } from './services/message.service';
import { ProgressBarComponent } from './components/shared/progress-bar/progress-bar.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgxUploaderModule } from 'ngx-uploader';
import { NativeElementInjectorDirective } from './components/shared/native-element.directive';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfPreviewComponent } from './components/mandate/createmandat/pdf-preview/pdf-preview.component';
import { AutoLogoutService } from './services/auto-logout.service';
import { CommentsComponent as OlderCommentsComponent } from './components/mandate/createmandat/comments-container/comments/comments.component';
import { CommentListComponent as OlderCommentListComponent } from './components/mandate/createmandat/comments-container/commentList/commentList.component';
import { CommentFormComponent as OlderCommentFormComponent } from './components/mandate/createmandat/comments-container/commentForm/commentForm.component';
import { CommentContainer as OlderCommentContainer } from './components/mandate/createmandat/comments-container/comments-container.component';
import { CommentsService as OlderCommentsService } from './components/mandate/createmandat/comments-container/comments.service';
import { SharedModule } from './shared/shared.module';
import { UserAuthModule } from './user-auth/user-auth.module';
import { CheckDeactivationGuard } from './guards/checkDeactivation.guard';
import { InstituteGuard } from './guards/institute.guard';
import * as EJ2_LOCALE from "./translation.json";
import { L10n, setCulture } from '@syncfusion/ej2-base';
L10n.load({ de: EJ2_LOCALE.de });
setCulture("de");

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    DropdownDirective,
    CreatemandantComponent,
    ListmandantenComponent,
    CreatemandatComponent,
    ListmandateComponent,
    ListWerkstattPartnersComponent,
    CreateinsurerComponent,
    ListinsurersComponent,
    ConfirmationDialogComponent,
    ApplayoutComponent,
    MandatsTableComponent,
    CreateWerkstattPartnerComponent,
    CreatekfzpartnerComponent,
    ListkfzpartnersComponent,
    SnackbarComponent,
    AppalertsComponent,
    ProgressBarComponent,
    NativeElementInjectorDirective,
    PdfPreviewComponent,
    OlderCommentsComponent,
    OlderCommentContainer,
    OlderCommentListComponent,
    OlderCommentFormComponent
  ],
  entryComponents: [AppalertsComponent, PdfPreviewComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressBarModule,
    AllMaterialModule,
    AlertModule,
    [MatPasswordStrengthModule.forRoot()],
    MatPasswordStrengthModule,
    MatDialogModule,
    NgxUploaderModule,
    PdfViewerModule,
    SharedModule,
    UserAuthModule,
    MatTreeModule
  ],
  exports: [OlderCommentsComponent],
  providers: [OlderCommentsService,UserService, AutoLogoutService, AuthGuard, ConfirmGuard, CheckDeactivationGuard, InstituteGuard, TokenInterceptorService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  }, MandantenService, MandateService, DatePipe,
    BnNgIdleService, SnackbarService,
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MAT_SNACK_BAR_DATA, useValue: [] },
    { provide: MatPaginatorIntl, useValue: getDeutchPaginatorIntl() },
    HttpErrorHandler,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
}

// platformBrowserDynamic().bootstrapModule(AppModule);
