import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SharedService } from '../services/shared.service';

@Injectable()
export class InstituteGuard implements CanActivate{
    public userDetail: any;
    constructor(private router: Router, private sharedService: SharedService) {
        this.userDetail = sharedService.userDetail();
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if(route.params['type'] === 'versicherungen'){
            return true;
        }

        if(route.params['type'] === 'werkstatten'){
            return this.userDetail.permissions.includes('canViewCRS');
        }

        if(route.params['type'] === 'sachverstandige'){
            return this.userDetail.permissions.includes('canViewExpert');
        }
        this.router.navigate(['/dashboard']);
        return false;
    }
}
