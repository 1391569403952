import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { Observable } from 'rxjs';
import { configData } from '../../config.data';

@Injectable()
export class UserService {

  constructor(private http: HttpClient, private router: Router) { }
  // connection to register route in express app
  expressLink = configData.SERVER + 'users';
  // loggedinUser: User;
  loggedinUser = this.getUser();    //localStorage.getItem('user');
  defaultMenuSelected = localStorage.getItem('defaultMenuSelected');
  // Register User

  register(body: any) {
    return this.http.post(this.expressLink + '/register', body, {
      observe: 'body',
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Verify email address of user

  verifyUser(username, key) {
    return this.http.get(this.expressLink + '/verify/' + username + '/' + key, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Update theme preference for user selection
  

  // login User

  login(body: any) {
    return this.http.post(this.expressLink + '/login', body, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Send reset password link

  

  // Verify the password reset link validity

  resetlinkVerify(username, key) {
    return this.http.get(this.expressLink + '/resetlink/' + username + '/' + key, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // reset password with link and send email after reset

  resetPwdLink(body: any, username) {
    return this.http.post(this.expressLink + '/resetlink/' + username, body, {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // reset Password after Login

  // Get username for Dashboard

  dashboard() {
    return this.http.get(this.expressLink + '/dashboard/', {
      observe: 'body',
      withCredentials: true, // Make cookie available in browser
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });
  }

  // Logout User

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      return '';
    }
    return JSON.parse(atob(token.split('.')[1]));
  }

  // Clear token and logout from other tabs
}
