import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Mandat } from '../../../models/mandat';
import { MandateService } from 'src/app/components/mandate/mandate.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Helpers } from '../helper-functions';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-mandats-table',
  templateUrl: './mandats-table.component.html',
  styleUrls: ['./mandats-table.component.css', '../../../../tableStyles.scss']
})
export class MandatsTableComponent implements OnInit, OnDestroy {

  @Input() mandate: Mandat[];
  firstColumns = ['aktenzeichen', 'unfallDatum', 'sachbearbeiter', 'kennzeichen', 'sachverständiger'];
  secondColumns = ['mandant', 'unfallgegner', 'gegnerischeHaftpflichtversicherung', 'markeModell', 'werkstatt'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalRecords = 0;
  filteredRecords = 0;
  helpers = new Helpers();
  private subs = new SubSink();
  anreden = [
    { id: 0, value: '-- Anrede auswählen --' },
  ];
  titels = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  begruendungSchuldfragelist = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  statuses = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  currentIndex = -1;

  constructor(private router: Router,
    private mandateService: MandateService, private dialog: MatDialog,
    private sharedService: SharedService,
    private snackbarService: SnackbarService,
    public datepipe: DatePipe) {
    this.getTypes();
    // this.listMandate();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  getTypes() {
    this.subs.sink = this.sharedService.getTypes().subscribe(
      types => {
        types.titel.forEach(r => {
          this.titels.push(r);
        });
        types.anrede.forEach(r => {
          this.anreden.push(r);
        });
        types.schuldfrage.forEach(r => {
          this.begruendungSchuldfragelist.push(r);
        });
        types.status.forEach(r => {
          this.statuses.push(r);
        });
      },
      err => {
        this.sharedService.progress.next(false);
      }
    );
  }

  getNameOrFirma(mandant) {
    return this.helpers.getNameOrFirma(mandant, this.anreden, this.titels);
  }

  getUGName(mandat) {
    return this.helpers.getUGName(mandat, this.anreden, this.titels);
  }

  getBegruendung(id) {
    return this.helpers.getBegruendungOrStatus(id, this.begruendungSchuldfragelist);
  }

  getStatus(id) {
    return this.helpers.getBegruendungOrStatus(id, this.statuses);
  }

  loadTable(mandate) {
    this.updateDatasource(mandate); this.mandate = mandate; this.totalRecords = mandate.length;
    this.filteredRecords = this.totalRecords;
  }

  // List mandate
  listMandate() {
    this.sharedService.progress.next(true);
    this.subs.sink = this.mandateService.viewMandateList()
      .subscribe(
        (mandate: Mandat[]) => {
          this.updateDatasource(mandate); this.mandate = mandate; this.totalRecords = mandate.length;
          this.filteredRecords = this.totalRecords;
          this.sharedService.progress.next(false);
        },
        err => {
          this.sharedService.progress.next(false);
        }
      );
  }

  showDetails(mandat: Mandat) {
    this.router.navigate(['mandate/mandat', mandat.mandantId, mandat.id]);
  }

  //#region Mat table data source
  updateDatasource(mandate) {
    this.firstColumns = ['aktenzeichen', 'unfallDatum', 'sachbearbeiter', 'kennzeichen', 'sachverständiger'];
    this.secondColumns = ['mandant', 'unfallgegner', 'gegnerischeHaftpflichtversicherung', 'markeModell', 'werkstatt'];
    this.dataSource = new MatTableDataSource(mandate);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLowerCase();
    this.dataSource.filterPredicate = (data, filter: string) => {
      const accumulator = (currentTerm, key) => {
        return this.nestedFilterCheck(currentTerm, data, key);
      };
      const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    };
    this.filteredRecords = this.dataSource.filteredData.length;
  }
  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  mouseover(index){
    this.currentIndex = index;
  }

  matSortChange({active, direction}){
    if(active === 'aktenzeichen'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({id : id1}, {id : id2}) => (direction === 'asc' ? 1 : -1) * parseInt(id1.split('F')[1]) - parseInt(id2.split('F')[1]));
    }

    if(active === 'mandant'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort((mandatFirst, mandatSecond) => {
        if((mandatSecond?.mandant?.type ? this.getNameOrFirma(mandatSecond.mandant) : '') > (mandatFirst?.mandant?.type ? this.getNameOrFirma(mandatFirst.mandant) : '')) { return -1*(direction === 'asc' ? 1 : -1) };
        if((mandatSecond?.mandant?.type ? this.getNameOrFirma(mandatSecond.mandant) : '') < (mandatFirst?.mandant?.type ? this.getNameOrFirma(mandatFirst.mandant) : '')) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'unfallgegner'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort((mandatFirst, mandatSecond) => {
        if(this.getUGName(mandatSecond) > this.getUGName(mandatFirst)) { return -1*(direction === 'asc' ? 1 : -1) };
        if(this.getUGName(mandatSecond) < this.getUGName(mandatFirst)) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'gegnerischeHaftpflichtversicherung'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({hvName : hvNameFirst}, {hvName : hvNameSecond}) => {
        if(hvNameSecond > hvNameFirst) { return -1*(direction === 'asc' ? 1 : -1) };
        if(hvNameSecond < hvNameFirst) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'markeModell'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({marke : markeFirst, model : modelFirst}, {marke : markeSecond, model : modelSecond}) => {
        if(`${markeSecond} ${modelSecond}` > `${markeFirst} ${modelFirst}`) { return -1*(direction === 'asc' ? 1 : -1) };
        if(`${markeSecond} ${modelSecond}` < `${markeFirst} ${modelFirst}`) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }


    if(active === 'werkstatt'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({werkstatt : werkstattFirst}, {werkstatt : werkstattSecond}) => {
        if(`${werkstattSecond}` > `${werkstattFirst}`) { return -1*(direction === 'asc' ? 1 : -1) };
        if(`${werkstattSecond}` < `${werkstattFirst}`) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'unfallDatum'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({unfallDatum : unfallDatumFirst}, {unfallDatum : unfallDatumSecond}) => {
        if(new Date(unfallDatumSecond) > new Date(unfallDatumFirst)) { return -1*(direction === 'asc' ? 1 : -1) };
        if(new Date(unfallDatumSecond) < new Date(unfallDatumFirst)) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'sachbearbeiter'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({mandat : mandatFirst}, {mandat : mandatSecond}) => {
        if(`${mandatSecond?.mandant?.createdBy}` > `${mandatFirst?.mandant?.createdBy}`) { return -1*(direction === 'asc' ? 1 : -1) };
        if(`${mandatSecond?.mandant?.createdBy}` < `${mandatFirst?.mandant?.createdBy}`) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'kennzeichen'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({kennzeichen : kennzeichenFirst}, {kennzeichen : kennzeichenSecond}) => {
        if(`${kennzeichenSecond}` > `${kennzeichenFirst}`) { return -1*(direction === 'asc' ? 1 : -1) };
        if(`${kennzeichenSecond}` < `${kennzeichenFirst}`) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }

    if(active === 'sachverständiger'){
      this.dataSource.sortData = (data : any, sortObj) => !direction ? data : data.sort(({kfzSachverstaendiger : kfzSachverstaendigerFirst}, {kfzSachverstaendiger : kfzSachverstaendigerSecond}) => {
        if(`${kfzSachverstaendigerSecond}` > `${kfzSachverstaendigerFirst}`) { return -1*(direction === 'asc' ? 1 : -1) };
        if(`${kfzSachverstaendigerSecond}` < `${kfzSachverstaendigerFirst}`) { return 1*(direction === 'asc' ? 1 : -1) };
        return 0;
      })
    }
  }
  
}
