import { AutoLogoutService } from './services/auto-logout.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UserService } from 'src/app/components/userlogin/user.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { HostListener } from '@angular/core';
import { SubSink } from 'subsink';
import { Router, NavigationStart } from '@angular/router';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  private subs = new SubSink();

  constructor(public userService: UserService,
    private changeDetectorRef: ChangeDetectorRef, private bnIdle: BnNgIdleService, private router: Router,
    private snackbarService: SnackbarService, private sharedService: SharedService,
    private autoLogoutService: AutoLogoutService,) {
    /* this.bs.channel.onmessage = event => {
      this.userService.logoutUser();
      changeDetectorRef.detectChanges();
    }; */
    this.sharedService.listenerLogin();
    // this.sharedService.getTypes().subscribe(data => {
    //   console.log(data);
    //   this.sharedService.types.next(data);
    // });

    // To monitor and logout if the user is inactive for the mentioned duration
    /* this.subs.sink = this.bnIdle.startWatching(3000).subscribe((res) => {
      this.sharedService.progress.next(true);
      if (res && localStorage.getItem('token') != null) {
        this.snackbarService.open('You have been logged out due to inactivity!', 'warning');
        this.userService.logoutUser();
      }
      this.sharedService.progress.next(false);
    }); */

    this.subs.sink = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }
  ngOnInit() {
    // this.testDataService.getRegUsersTestData().subscribe(data => {
    //   console.log(data);
    // })
    /* if (JSON.parse(localStorage.getItem('expiresAt')) > Date.now()) {
      console.log(localStorage.getItem('expiresAt'), Date.now());
      this.userService.logoutUser();
    } */
    // Logout user after application restart
    /* if (localStorage.getItem('token') != null) {
      this.userService.logout()
        .subscribe(
          data => {
            this.userService.logoutUser();
          },
          error => console.error(error)
        );
    } */
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }
  /*     @HostListener('window:unload', ['$event'])
      unloadHandler(event) {
        this.userService.logoutUser();
      } */
}
