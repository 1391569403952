import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { RegisterComponent } from '../register/register.component';
import { SharedService } from 'src/app/services/shared.service';
import { SubSink } from 'subsink';
import { AppService } from 'src/app/services/app.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  private subs = new SubSink();
  public requesting = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private sharedService: SharedService,
    public dialog: MatDialog, public appService: AppService) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [null, [Validators.required, Validators.minLength(5)]],
      password: [null, [Validators.required, Validators.minLength(5)]],
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  openDialogPassword(): void {
    this.dialog.open(ForgotpasswordComponent, {
      width: '450px'
    });
  }

  openDialogRegister(): void {
    this.dialog.open(RegisterComponent, {
      width: 'auto'
    });
  }

  get getUsername() { return this.loginForm.get('username'); }
  get getPassword() { return this.loginForm.get('password'); }

  login() {
    if(!this.requesting) {
      if (!this.loginForm.valid) {
        this.validateAllFormFields(this.loginForm);
        return;
      } else {
        this.requesting = true;
        this.sharedService.progress.next(true);
        this.subs.sink = this.appService.login(JSON.stringify(this.loginForm.value))
          .pipe(finalize(() => {this.sharedService.progress.next(false); this.requesting = false;}))
          .subscribe(
            data => {
              this.updateDetails(data);
              this.router.navigate(['/dashboard']);
              this.sharedService.open('Anmeldung erfolgreich.', 'success');
              this.sharedService.userLoginSuccess.next(true);
            },
            error => {
              console.error(error);
              this.sharedService.open('Login failure!', 'failure');
            }
          );
      }
    }
  }

  updateDetails(data) {
    localStorage.setItem('token', data.data.result.token);
    localStorage.setItem('userDetail', window.btoa(JSON.stringify(data.data.result.user)));
    // localStorage.setItem('defaultThemeColour', data.result.user.defaultThemeColour);
    // localStorage.setItem('defaultMenuSelected', 'Übersicht');
    // this.userService.loggedinUser = data.user.username;
    // this.userService.currentTheme = data.user.defaultThemeColour;
    // this.userService.defaultMenuSelected = 'Übersicht';
    // if (this.userService.currentTheme !== undefined && this.userService.currentTheme !== null
    //   && this.userService.currentTheme !== '') {
    //   document.body.className = '';
    //   document.body.classList.add(`${this.userService.currentTheme}-theme`);
    // }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
