import { Component, OnInit, HostListener, ViewChild, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { MandantenService } from '../mandanten.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Mandant } from 'src/app/models/mandant';
import { Mandat } from 'src/app/models/mandat';
import { Location } from '@angular/common';
import { MandatsTableComponent } from '../../shared/mandats-table/mandats-table.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Helpers } from 'src/app/components/shared/helper-functions';
import { SubSink } from 'subsink';
import { browserRefresh } from 'src/app/app.component';
import { distinct } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { MandateService } from '../../mandate/mandate.service';

@Component({
  selector: 'app-createmandant',
  templateUrl: './createmandant.component.html',
  styleUrls: ['./createmandant.component.css', '../../../../assets/global.css']
})
export class CreatemandantComponent implements OnInit, OnDestroy {

  //#region Initializations
  mandantForm: FormGroup;
  mandant: Mandant;
  mandate: Mandat[] = [];
  displayedColumns: string[];
  @ViewChild(MandatsTableComponent) child: MandatsTableComponent;
  mandantID: string;
  public errMsg = {
    message: 'No alerts'
  };
  submitted = false;
  anreden = [
    { id: 0, value: '-- Anrede auswählen --' },
  ];
  titels = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  objectTypes = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  saved = false;
  created = false;
  pageMode = 'create';
  helpers = new Helpers();
  private subs = new SubSink();
  //#endregion

  constructor(public router: Router, private mandantenService: MandantenService,
    private snackbarService: SnackbarService, private formBuilder: FormBuilder,
    private sharedService: SharedService, private currentRoute: ActivatedRoute,
    private mandateService: MandateService, private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,
    private matDialogRef: MatDialogRef<any>) {
    this.getTypes();
    this.subs.sink = this.currentRoute.params.subscribe(
      params => {
        this.mandantID = params.mandantID;
      }
    );
    if (data.mandantID) {
      this.mandantID = data.mandantID;
    }
    if (this.mandantID) {
      this.pageMode = 'view';
      this.subs.sink = this.mandantenService.viewOneMandant(this.mandantID)
        .subscribe(
          (mandant: Mandant) => { this.mandant = mandant; console.log(mandant); this.proceed(); }
        );
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.saved || this.created || !this.mandantForm.dirty) {
      if (!this.created && this.pageMode === 'edit') {
        this.clearLock();
      }
      return true;
    } else {
      if (confirm('Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?')) {
        this.clearLock();
        return true;
      } else {
        return false;
      }
    }
  }

  clearLock() {
    this.subs.sink = this.sharedService.clearLock(this.mandantID)
      .subscribe(data => {
      });
  }

  ngOnDestroy() {
    this.clearLock();
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  ngOnInit() {
    this.mandantForm = this.formBuilder.group({
      id: new FormControl(null, Validators.nullValidator),
      type: new FormControl(1, Validators.required),
      firmenName: new FormControl(null, [Validators.nullValidator]),
      ustId: new FormControl(null, [Validators.nullValidator]),
      anrede: new FormControl(this.anreden[0].id, [Validators.required, this.customRequired.bind(this)]),
      titel: new FormControl(this.titels[0].id, [Validators.nullValidator]),
      vorname: new FormControl(null, [Validators.required]),
      nachname: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.email, Validators.nullValidator]),
      strasse: new FormControl(null, [Validators.required]),
      hausNummer: new FormControl(null, [Validators.required]),
      plz: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
      ort: new FormControl(null, Validators.required),
      telefonNummer: new FormControl(null, [Validators.nullValidator, Validators.pattern('[0-9]*'), Validators.maxLength(30)]),
      iban: new FormControl(null, Validators.nullValidator),
      bank: new FormControl(null, Validators.nullValidator),
      telRef: new FormControl(null, Validators.nullValidator),
      emailRef: new FormControl(null, Validators.nullValidator),
      kontoinhaber: new FormControl(null, Validators.nullValidator),
      vorsteuer: new FormControl(false, Validators.nullValidator)
    });

    this.subs.sink = this.type.valueChanges.subscribe(type => {
      if (type === 2) { // 'Unternehmen'
        this.mandantForm.get('anrede').setValue(this.anreden[0].id);
        this.mandantForm.get('titel').setValue(this.titels[0].id);
        this.mandantForm.get('vorname').reset();
        this.mandantForm.get('nachname').reset();
        this.mandantForm.get('anrede').clearValidators();
        this.mandantForm.get('titel').clearValidators();
        this.mandantForm.get('vorname').clearValidators();
        this.mandantForm.get('nachname').clearValidators();
        this.mandantForm.get('anrede').updateValueAndValidity();
        this.mandantForm.get('titel').updateValueAndValidity();
        this.mandantForm.get('vorname').updateValueAndValidity();
        this.mandantForm.get('nachname').updateValueAndValidity();
      } else {
        this.mandantForm.get('anrede').setValidators(this.customRequired.bind(this));
        this.mandantForm.get('firmenName').reset();
        this.mandantForm.get('firmenName').clearValidators();
        this.mandantForm.get('firmenName').updateValueAndValidity();
      }
    });

    if (this.data.mandantID) {
      this.subs.sink = this.matDialogRef.afterClosed().subscribe(action => {
        this.clearLock();
      });
    }

    if (browserRefresh) {
      this.clearLock();
    }
  }

  getTypes() {
    this.subs.sink = this.sharedService.getTypes().subscribe(
      types => {
        types.titel.forEach(r => {
          this.titels.push(r);
        });
        types.anrede.forEach(r => {
          this.anreden.push(r);
        });
        this.objectTypes = types.objectTypes;
      },
      err => {
        this.router.navigate(['/mandanten/list']);
      }
    );
  }

  get type() {
    return this.mandantForm.get('type') as FormControl;
  }

  proceed() {
    if (this.mandantID) {
      this.subs.sink = this.mandateService.viewMandateofMandant(this.mandantID)
        .subscribe(
          (mandate: Mandat[]) => {
            this.mandate = mandate;
            this.viewMandant();
            this.child.loadTable(this.mandate);
          }
        );
    } else if (this.router.url.includes('create')) {
      this.router.navigate(['/mandant/create']);
    } else {
      this.location.back();
    }
  }

  //#region Validations
  customRequired(control: AbstractControl): { [key: string]: any } | null {
    const anrede: number = control.value;
    if (anrede === 0) {
      return { customRequired: true };
    } else {
      return null;
    }

  }
  get validations() { return this.mandantForm.controls; }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  //#region CreateMandant functions

  createMandant() {
    console.log(this.mandantForm.value);
    this.submitted = true;
    if (!this.mandantForm.valid) {
      this.validateAllFormFields(this.mandantForm);
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.mandantenService.createMandant(JSON.stringify(this.mandantForm.value))
        .subscribe(
          (data: Mandant) => {
            console.log(data); this.created = true; this.router.navigate(['/mandanten/list']);
            this.snackbarService.open(`Mandant ${data.id}
            ${this.helpers.getNameOrFirma(data, this.anreden, this.titels)} wurde angelegt.`, 'success');
          },
          error => {
            { console.error(error); this.createError(error); this.snackbarService.open(this.errMsg.message, 'failure'); }
          }
        );
      this.sharedService.progress.next(false);
    }
  }

  createError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }
  //#endregion

  closeMandantModal() {
    // this.matDialogRef.close();
    this.dialog.closeAll();
  }

  // Delete Mandant by ID

  deleteMandant() {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.sharedService.progress.next(true);
      this.subs.sink = this.mandantenService.deleteMandant(this.mandantID)
        .subscribe(data => {
          this.clearLock();
          this.router.navigate(['/mandanten/list']);
        });
      this.sharedService.progress.next(false);
    }
  }


  //#region Populate Mandant form
  editMandant() {
    if (this.pageMode === 'view') {
      this.subs.sink = this.sharedService.checkLock('mandant', this.mandantID)
        .subscribe(data => {
          this.pageMode = 'edit';
          this.mandantForm.patchValue(this.mandant);
          this.mandantForm.enable();
        }, error => {
          this.createError(error);
          this.snackbarService.open(this.errMsg.message, 'failure');
        });
    } else {
      this.saveMandant();
    }
  }

  viewMandant() {
    this.pageMode = 'view';
    this.mandantForm.disable();
    this.mandantForm.patchValue(this.mandant);
  }
  //#endregion
  //#region Save Mandant functions
  saveMandant() {
    if (!this.mandantForm.valid) {
      this.validateAllFormFields(this.mandantForm);
      console.log('invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      console.log(this.mandantForm.value);
      this.subs.sink = this.mandantenService
        .updateMandant(
          JSON.stringify(this.mandantForm.value),
          this.mandantID
        )
        .subscribe(
          (data: { mandant: Mandant, changed: boolean }) => {
            this.mandant = data.mandant;
            this.saved = true; this.viewMandant(); this.clearLock();
            if (data.changed)
              this.snackbarService.open(`Mandant ${this.mandant.id} ${this.helpers.getNameOrFirma(this.mandant, this.anreden, this.titels)}
            wurde gespeichert`, 'success');
            else this.snackbarService.open(`No details changed`, 'success');
            if (this.data.mandantID) {
              this.matDialogRef.close();
            }
          },
          error => {
            {
              console.error(error);
              this.createError(error);
              this.snackbarService.open(this.errMsg.message, 'failure');
            }
          }
        );
      this.sharedService.progress.next(false);
    }
  }
  //#endregion
}
