import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../components/userlogin/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-verifyuser',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.css']
})
export class VerifyuserComponent implements OnInit {
  public errMsg = {
    message: 'No alerts'
  };

  routeParams: any;
  username: '';
  token: '';
  verified: any;
  constructor(private router: Router, private userService: UserService, private currentRoute: ActivatedRoute,
    private snackbarService: SnackbarService) {
    this.routeParams = this.currentRoute.params.subscribe(
      params => {
        this.username = params.username;
        this.token = params.token;
      }
    );
    this.userService.verifyUser(this.username, this.token)
      .subscribe(
        data => {
          console.log(data); this.router.navigate(['/login']);
          this.verified = 'Email has been verified now';
          this.snackbarService.open('Email has been verified, you can login now!', 'success');
        },
        error => {
          {
            console.error(error); this.verifyError(error); this.router.navigate(['/login']);
            this.snackbarService.open(this.errMsg.message, 'failure');
          }
        }
      );
  }

  ngOnInit() {
  }
  verifyError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }
}
