export const configData = {
    SERVER: '', //'http://localhost:3000',
    version: '/api/v1',
    login: '/users/login',
    forgotPassword: '/users/forgotpassword',
    resetPassword: '/users/resetpassword',
    logout: '/users/logout',
    updateTheme: '/users/updateTheme',
    typeOfInstitution: '/shared/typeOfInstitution',
    institute: '/institute',
    user: '/user',
    titleAndSalutation: '/shared/titleAndSalutation',
    client: '/client',
    caseValues: '/shared/caseValues',
    case: '/case',
    download: '/download',
    comments: '/comments',
    instituteType: '/instituteType',
    document: '/documents',
    folder: '/folder',
    file: '/file',
    mailTemplate: '/mailTemplate',
    favorite: '/favorite',
    caseVariable: '/caseVariable',
    textBlockTemplate: '/textBlockTemplate',
    sendMail: '/send-mail',
    documentTemplate: '/documentTemplate',
    conditions: '/conditions',
};
