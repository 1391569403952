import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../components/userlogin/user.service';
import { MustMatch } from '../../components/shared/MustMatch.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { SubSink } from 'subsink';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup;
  public errMsg = {
    message: 'No alerts'
  };
  submitted = false;
  private subs = new SubSink();

  constructor(private router: Router, private userService: UserService, private formBuilder: FormBuilder,
    private snackbarService: SnackbarService, public dialogRef: MatDialogRef<RegisterComponent>,
    private sharedService: SharedService) {

  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: new FormControl(null, [Validators.email, Validators.required]),
      username: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      password: new FormControl(null, [Validators.required, Validators.minLength(5)]),
      cpass: new FormControl(null, Validators.required)
    },
      {
        validator: MustMatch('password', 'cpass')
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get getEmail() { return this.registerForm.get('email'); }
  get getUsername() { return this.registerForm.get('username'); }
  get getPassword() { return this.registerForm.get('password'); }
  get getCPass() { return this.registerForm.get('cpass'); }

  get validations() { return this.registerForm.controls; }

  // ===================== Login function ========================== //
  moveToLogin() {
    this.router.navigate(['./login']);
  }

  // ===================== Register functions ========================== //

  register() {
    this.submitted = true;
    if (!this.registerForm.valid) {
      this.validateAllFormFields(this.registerForm);
      console.log('invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.userService.register(JSON.stringify(this.registerForm.value))
        .subscribe(
          data => {
            console.log(data); this.router.navigate(['/login']); this.onNoClick();
            this.snackbarService.open('Registration successful! Please verify your email', 'success');
          },
          error => {
            { console.error(error); this.registerError(error); this.snackbarService.open(this.errMsg.message, 'failure'); }
          }
        );
      this.sharedService.progress.next(false);
    }
  }
  registerError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }

  // ===================== Validations ========================== //

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // ===================== Reset function ========================== //

  resetForm() {
    this.submitted = false;
    this.registerForm.reset();
  }
}
