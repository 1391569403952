import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SubSink } from 'subsink';
import { MustMatch } from '../../components/shared/MustMatch.validator';
import { SharedService } from 'src/app/services/shared.service';
import { AppService } from 'src/app/services/app.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit, OnDestroy {

  public resetpwdForm: FormGroup;
  private subs = new SubSink();

  constructor(private router: Router, private formBuilder: FormBuilder, public appService: AppService,
    private sharedService: SharedService) { }

  ngOnInit() {
    this.resetpwdForm = this.formBuilder.group({
      oldPassword: [null, [Validators.required, Validators.minLength(5)]],
      newPassword: [null, [Validators.required, Validators.minLength(5)]],
      cpass: [null, Validators.required]
    },
      {
        validator: MustMatch('newPassword', 'cpass')
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  get getOldPassword() { return this.resetpwdForm.get('oldPassword'); }
  get getPassword() { return this.resetpwdForm.get('newPassword'); }
  get getCPass() { return this.resetpwdForm.get('cpass'); }

  resetPassword() {
    if (!this.resetpwdForm.valid) {
      this.validateAllFormFields(this.resetpwdForm);
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.appService.resetPassword(JSON.stringify(this.resetpwdForm.value))
        .pipe(finalize(() => this.sharedService.progress.next(false)))
        .subscribe(
          data => {
            this.sharedService.open('Password reset successful!', 'success');
            this.router.navigate(['/']);
          },
          error => {
            console.error(error);
            this.sharedService.open(error.error.message, 'failure');
          }
        );
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  
  get validations() { return this.resetpwdForm.controls; }

}
