import { KfzpartnerService } from '../kfzpartner.service';
import { Component, OnInit, ViewChild, HostListener, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  FormArray
} from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { KFZPartner, KFZAnsprechPartner } from 'src/app/models/sachverstaendige';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import { CanComponentDeactivate } from '../../../guards/auth.guard';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MandateService } from 'src/app/components/mandate/mandate.service';
import { Mandat } from 'src/app/models/mandat';
import { MandatsTableComponent } from '../../shared/mandats-table/mandats-table.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { Helpers } from '../../shared/helper-functions';
import { SubSink } from 'subsink';
import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-createkfzpartner',
  templateUrl: './createkfzpartner.component.html',
  styleUrls: ['./createkfzpartner.component.css', '../../../../assets/global.css']
})
export class CreatekfzpartnerComponent implements OnInit, CanComponentDeactivate, OnDestroy {

  //#region Initializations

  partnerForm: FormGroup;
  partner: KFZPartner;
  ansprechPartner1: KFZAnsprechPartner[] = [];
  modifiedAPs: KFZAnsprechPartner[] = [];
  partnerID: string;
  ansprechPartner: FormArray;
  @ViewChild(MatSort) sort: MatSort;
  public errMsg = {
    message: 'No alerts'
  };
  @ViewChild(MandatsTableComponent) child: MandatsTableComponent;
  created = false;
  saved = false;
  apAddFlag = false;
  apEditFlag = false;
  pageMode = 'create';
  anreden = [
    { id: 0, value: '-- Anrede auswählen --' },
  ];
  titels = [
    { id: 0, value: '-- Titel auswählen --' }
  ];
  priorities = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  objectTypes = [
    { id: 0, value: '-- Bitte auswählen --' }
  ];
  displayAPColumns = ['name', 'email', 'telefonNummer', 'options'];
  aPDataSource: any;
  public apFormData: any;
  apIndex: any;
  helpers = new Helpers();
  private subs = new SubSink();
  apModal: MatDialogRef<unknown, any>;
  //#endregion

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private partnerService: KfzpartnerService,
    private formBuilder: FormBuilder,
    private location: Location,
    private sharedService: SharedService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    private mandateService: MandateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<any>
  ) {
    this.getTypes();
    this.subs.sink = this.route.paramMap.subscribe(params => {
      this.partnerID = params.get('partnerID');
      if (data.partnerID) {
        this.partnerID = data.partnerID;
      }
      if (this.partnerID) {
        this.pageMode = 'view';
        this.subs.sink = this.partnerService.viewOnePartner(this.partnerID)
          .subscribe(
            (results) => { console.log(results); this.proceed(results); }
          );
      }
    });
  }

  @HostListener('window:beforeunload')
  canDeactivate() {
    if (this.saved || this.created || !this.partnerForm.dirty) {
      if (!this.created && this.pageMode === 'edit') {
        this.clearLock();
      }
      return true;
    } else {
      
/*       if (confirm('Möchten Sie die getätigten Änderungen verwerfen und ohne Speichern verlassen?')) {
        this.clearLock();
        return true;
      } else {
        return false;
      } */
    }
  }

  clearLock() {
    this.subs.sink = this.sharedService.clearLock(this.partnerID)
      .subscribe(data => {
      });
  }

  ngOnDestroy() {
    this.clearLock();
    this.subs.unsubscribe();
    this.sharedService.progress.next(false);
  }

  ngOnInit(): void {
    this.partnerForm = this.formBuilder.group({
      id: new FormControl(null, Validators.nullValidator),
      type: new FormControl(1, Validators.required), // 'natürliche Person'
      firmenName: new FormControl(null),
      ustId: new FormControl(null),
      anrede: new FormControl(this.anreden[0].id, [this.customRequired.bind(this)]),
      titel: new FormControl(this.titels[0].id, [Validators.nullValidator]),
      vorname: new FormControl(null, [Validators.required]),
      nachname: new FormControl(null, [Validators.required]),
      strasse: new FormControl(null, [Validators.required]),
      hausNummer: new FormControl(null, [Validators.required]),
      plz: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*')]),
      ort: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.email, Validators.required]),
      telefonNummer: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(30)]),
      iban: new FormControl(null, Validators.nullValidator),
      bank: new FormControl(null, Validators.nullValidator),
      priority: new FormControl(this.priorities[0].id, [this.customRequired.bind(this)]),
      ablehnen: new FormControl(false, [Validators.nullValidator]),
      ansprechPartner: new FormArray([]),
    });


    this.subs.sink = this.type.valueChanges.subscribe(type => {
      if (type === 2) { // 'Unternehmen'
        this.partnerForm.get('anrede').setValue(this.anreden[0].id);
        this.partnerForm.get('titel').setValue(this.titels[0].id);
        this.partnerForm.get('vorname').reset();
        this.partnerForm.get('nachname').reset();
        this.partnerForm.get('anrede').clearValidators();
        this.partnerForm.get('titel').clearValidators();
        this.partnerForm.get('vorname').clearValidators();
        this.partnerForm.get('nachname').clearValidators();
        this.partnerForm.get('anrede').updateValueAndValidity();
        this.partnerForm.get('titel').updateValueAndValidity();
        this.partnerForm.get('vorname').updateValueAndValidity();
        this.partnerForm.get('nachname').updateValueAndValidity();
      } else {
        this.partnerForm.get('anrede').setValidators(this.customRequired.bind(this));
        this.partnerForm.get('firmenName').reset();
        this.partnerForm.get('firmenName').clearValidators();
        this.partnerForm.get('firmenName').updateValueAndValidity();
      }
    });

    if (this.data.partnerID) {
      this.subs.sink = this.matDialogRef.afterClosed().subscribe(action => {
        this.clearLock();
      });
    }

    if (browserRefresh) {
      this.clearLock();
    }
  }

  get type() {
    return this.partnerForm.get('type') as FormControl;
  }

  createAnsprechPartner(): FormGroup {
    const first = this;
    return this.formBuilder.group({
      id: new FormControl(null, Validators.nullValidator),
      anrede: new FormControl(this.anreden[0].id, [Validators.required, this.customRequired.bind(this)]),
      titel: new FormControl(this.titels[0].id, [Validators.nullValidator]),
      vorname: new FormControl(null, [Validators.required]),
      nachname: new FormControl(null, [Validators.required, this.checkduplicateAP.bind(this)]),
      email: new FormControl(null, [Validators.email, Validators.required, this.checkduplicateAP.bind(this)]),
      telefonNummer: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(30)]),
      deleted: new FormControl(null, Validators.nullValidator),
      sachverstaendigeId: new FormControl(null, Validators.nullValidator),
    });
  }

  setexistingAnsprechPartner(ansprechPartner: KFZAnsprechPartner[]): FormArray {
    const formArray = new FormArray([]);
    ansprechPartner.forEach(s => {
      formArray.push(
        this.formBuilder.group({
          id: s.id,
          anrede: s.anrede,
          titel: s.titel,
          vorname: s.vorname,
          nachname: s.nachname,
          email: s.email,
          telefonNummer: s.telefonNummer,
          deleted: s.deleted,
          sachverstaendigeId: s.sachverstaendigeId
        })
      );
    });
    return formArray;
  }

  //#region Validations
  customRequired(control: AbstractControl): { [key: string]: any } | null {
    const anrede: number = control.value;
    if (anrede === 0) {
      return { customRequired: true };
    } else {
      return null;
    }

  }
  get validations() { return this.partnerForm.controls; }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  //#endregion

  getTypes() {
    this.subs.sink = this.sharedService.getTypes().subscribe(
      types => {
        types.titel.forEach(r => {
          this.titels.push(r);
        });
        types.anrede.forEach(r => {
          this.anreden.push(r);
        });
        types.priority.forEach(r => {
          this.priorities.push(r);
        });
        this.objectTypes = types.objectTypes;
      },
      err => {
        this.router.navigate(['/kfzPartner/list']);
      }
    );
  }

  getNameOrFirma(partner) {
    return this.helpers.getNameOrFirma(partner, this.anreden, this.titels);
  }

  // Proceed aftet ngOnInit finishes subscribe

  proceed(results) {
    this.partner = results;
    this.ansprechPartner1 = results.ansprechPartners;
    if (this.partnerID && this.router.url.includes('kfzpartner')) {
      this.subs.sink = this.mandateService.viewMandateofKFZPartner(this.partnerID)
        .subscribe(
          (mandate: Mandat[]) => { this.child.loadTable(mandate); this.viewPartner(); }
        );
    } else if (
      this.router.url.includes('create')
    ) {
      this.router.navigate(['/kfzPartner/create']);
    } else if (this.partnerID && this.router.url.includes('mandat')) {
      this.viewPartner();
    } else {
      this.location.back();
    }
  }

  viewPartner() {
    this.pageMode = 'view';
    this.partnerForm.disable();
    this.partnerForm.patchValue(this.partner);
    this.updateAPDatasource();
  }

  //#region CreatePartner functions

  createPartner() {
    console.log(this.partnerForm.value);
    if (!this.partnerForm.valid) {
      this.validateAllFormFields(this.partnerForm);
      console.log('invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.partnerService.createPartner(JSON.stringify(this.partnerForm.value))
        .subscribe(
          (data: KFZPartner) => {
            this.created = true; this.location.back();
            // console.log(data); this.router.navigate(['/kfzPartner/list']);
            this.snackbarService.open(`Partner ${data.id}
            ${this.helpers.getNameOrFirma(data, this.anreden, this.titels)} wurde angelegt`, 'success');
          },
          error => {
            { console.error(error); this.createError(error); this.snackbarService.open(this.errMsg.message, 'failure'); }
          }
        );
      this.sharedService.progress.next(false);
    }
  }

  createError(error: HttpErrorResponse) {
    this.errMsg = error.error;
  }
  //#endregion

  newApModal(aPartnerTemplate) {
    this.apEditFlag = false;
    this.apAddFlag = true;
    this.apFormData = this.createAnsprechPartner();
    this.apModal = this.dialog.open(aPartnerTemplate, {
      width: 'auto', disableClose: true
    });
  }

  editAPModal(aPartnerTemplate, row, index) {
    this.apAddFlag = false;
    this.apEditFlag = true;
    this.apFormData = row;
    this.apIndex = index;
    this.apModal = this.dialog.open(aPartnerTemplate, {
      width: 'auto', disableClose: true
    });
  }

  closeAPModal(): void {
    this.updateAPDatasource();
    this.apModal.close();
  }

  closePartnerModal(): void {
    this.dialog.closeAll();
  }

  checkduplicateAP(control: AbstractControl): { [key: string]: any } | null {
    const aPArray = this.partnerForm.get('ansprechPartner').value;
    return this.helpers.checkduplicate(control, aPArray);
  }
  addAPartner() {
    if (!this.apFormData.valid) {
      this.validateAllFormFields(this.apFormData);
    } else if (this.apFormData.dirty && this.apFormData.valid) {
      this.ansprechPartner1.push(this.apFormData.value);
      this.updateAPDatasource(); this.closeAPModal();
    } else {
      alert('No changes made');
    }
  }

  saveAPartner(index) {
    if (!this.apFormData.valid) {
      this.validateAllFormFields(this.apFormData);
    } else if (this.apFormData.dirty && this.apFormData.valid) {
      this.ansprechPartner1[index] = this.apFormData.value;
      this.updateAPDatasource();
      this.closeAPModal();
    } else {
      this.snackbarService.open('No changes made', 'warning');
    }
  }

  // Delete Partner

  deleteAP(index) {
    if (confirm('Möchten Sie mit dem löschen fortfahren? ')) {
      this.ansprechPartner1.splice(index, 1);
      this.updateAPDatasource();
    }
  }

  updateAPDatasource() {
    this.partnerForm.setControl(
      'ansprechPartner',
      this.setexistingAnsprechPartner(this.ansprechPartner1)
    );
    this.aPDataSource = new MatTableDataSource((this.partnerForm.get('ansprechPartner') as FormArray).controls);
    this.aPDataSource.sort = this.sort;
  }

  //#region Populate Partner form
  editPartner() {
    if (this.pageMode === 'view') {
      this.subs.sink = this.sharedService.checkLock('kfzPartner', this.partnerID)
        .subscribe(data => {
          this.pageMode = 'edit';
          this.partnerForm.enable();
        }, error => {
          this.createError(error);
          this.snackbarService.open(this.errMsg.message, 'failure');
        });
    } else {
      this.savePartner();
    }
  }
  //#endregion

  //#region Save Partner functions
  savePartner() {
    if (!this.partnerForm.valid) {
      this.validateAllFormFields(this.partnerForm);
      console.log(this.partnerForm, 'invalid');
      return;
    } else {
      this.sharedService.progress.next(true);
      this.subs.sink = this.partnerService
        .updatePartner(
          JSON.stringify(this.partnerForm.value),
          this.partnerID
        )
        .subscribe(
          (data: KFZPartner) => {
            this.saved = true;
            this.partner = data;
            this.ansprechPartner1 = data.ansprechPartners;
            this.viewPartner(); this.clearLock();
            this.snackbarService.open(`Partner ${this.partner.id}
             ${this.helpers.getNameOrFirma(this.partner, this.anreden, this.titels)} wurde gespeichert`, 'success');
            this.dialog.closeAll();
          },
          error => {
            {
              console.error(error);
              this.createError(error);
              this.snackbarService.open(this.errMsg.message, 'failure');
            }
          }
        );
      this.sharedService.progress.next(false);
    }
  }
  //#endregion

  // Delete Partner by ID

  deletePartner() {
    if (confirm('Möchten Sie mit dem löschen fortfahren?')) {
      this.sharedService.progress.next(true);
      this.subs.sink = this.partnerService.deletePartner(this.partnerID)
        .subscribe(data => {
          this.clearLock();
          this.router.navigate(['/kfzPartner/list']);
        });
      this.sharedService.progress.next(false);
    }
  }
}
