import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { CommentInterface } from "src/app/types/comment.interface";
import * as moment from 'moment';

@Component({
    selector: 'app-comment',
    templateUrl:'./comments.component.html',
    styleUrls: ['./comments.component.css']
})

export class CommentsComponent implements OnInit {
    @Input() height:any;
    @Input() currentUserId!: string;
    @Input() replies: CommentInterface[];
    @Input() parentId: string | null = null;
    @Input() allComment = [];
    @Input() comment!: CommentInterface;

    @Output() createComment = new EventEmitter<{text:string, parentId:string}>();
    @Output() updateComment = new EventEmitter<{text:string, commentId:string}>();
    @Output() deleteComment = new EventEmitter<any>();

    public isEditing: boolean = false;
    public isReplying: boolean = false
    public innerHeight = 0;

    ngOnInit(): void {
        this.manageHeight();
    }
    
    manageHeight(){
        this.height -= 60;
    }

    commentDate(){
        return moment(this.comment.updatedAt).format("H:mm");
    }

    handleCancel(e){
        this.isEditing = e;
        this.isReplying = e;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if(event.target.innerHeight !== this.innerHeight)
            this.manageHeight();

        this.innerHeight = event.target.innerHeight;
    }
}