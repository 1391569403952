import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { configData } from "src/app/config.data";
import { SharedService } from "src/app/services/shared.service";
import { CommentInterface } from "src/app/types/comment.interface";
import { UserService } from "../../../userlogin/user.service";

@Injectable()
export class CommentsService {
    constructor(public datepipe: DatePipe,public userService: UserService,private httpClient: HttpClient, public sharedService: SharedService) {}
    expressLink = configData.SERVER;
    getComments(mandatId): Observable<CommentInterface[]> {
        return this.httpClient.get<CommentInterface[]>(this.expressLink + `comments/${mandatId}`);
    }

    createComment(text: string, parentId: null|string, mandatId: string): Observable<CommentInterface> {
        var USER = this.sharedService.userDetail();
        console.log(this.userService.loggedinUser)
        console.log(localStorage)
        return this.httpClient.post<CommentInterface>(this.expressLink + 'comments/', {
            body: text,
            parentId,
            username: USER.username,
            userId: null,
            deleted: false,
            mandatId
        })
    }

    updateComment(id: number,text: string): Observable<CommentInterface> {
        console.log(this.expressLink + 'comments/' + id)
        return this.httpClient.patch<CommentInterface>(this.expressLink + 'comments/' + id, {
            body: text,
        })
    }

    deleteComment(id: number): Observable<{}> {
        return this.httpClient.delete(this.expressLink+ 'comments/' + id)
    }
}

